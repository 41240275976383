import {
    FETCH_BOOKMARK_LIST,
    FETCH_BOOKMARK_LIST_DONE,
    GET_BOOKMARK_LIST,
    SWITCH_BOOKMARK_ONLIST
} from '../constants/actionTypes';

const defaultState = {
    isLoading: false,
    bookmarkList: [],
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case FETCH_BOOKMARK_LIST:
            return {
                ...state,
                isLoading: true
            }
        case FETCH_BOOKMARK_LIST_DONE:
            return {
                ...state,
                isLoading: false
            }
        case GET_BOOKMARK_LIST:
            return {
                ...state,
                bookmarkList: action.bookmarkList,
                isLoading: false
            }
        case SWITCH_BOOKMARK_ONLIST:
            return {
                ...state,
                bookmarkList: action.bookmarkList,
                isLoading: false
            }
        default:
            return state
    }
}
