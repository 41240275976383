import React, { Component } from 'react';
import axios from "axios";
import { connect } from "react-redux"
import { compose } from "redux";
import { Link } from 'react-router-dom'
import { GET_ALL_PRODUCTS } from "../../config/apiUrl";

import {
    Grid, Typography, withStyles,
    Button, ButtonBase
  } from '@material-ui/core';

import { Paper } from 'material-ui';

const styles = {
    card: {
        minWidth: '450px',
        maxWidth: '450px',
        maxHeight: '500px',
        minHeight: '500px',
        marginLeft: '4px',
        marginRight: '4px',
        marginBottom: '20px',
        background: 'white',
        boxShadow: '0 3px 5px 2px #D9D8D8',
    },
    media: {
        height: '300px',
    },
    text: {
        textAlign: "left",
    },
    boxlist: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center"
    }
};

class ProductsBox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {},
            isLoading: false,
        }
    }

    componentDidMount = () => {
        this.getProducts()
    };

    getProducts(){
        this.setState({ isLoading: true})
        axios.get(GET_ALL_PRODUCTS()
        ).then(res => {
            console.log(res.data)
            this.setState({ 
                products: res.data,
                isLoading: false
            })
        }).catch(e => {
            console.log(e)
        })
    }

    render() {
        const { classes } = this.props;
        const products = this.state.products ? this.state.products : []
        console.log(products)
        const productsList = products.map((n, idx) =>{
            const m = n.categoryInfo
            return <div key={"vid_" + idx}>
                <Paper className={classes.card}>
                    <ButtonBase disableRipple>
                        <Link to={"/products/" + m.id} style={{ textDecoration: 'none' }} >
                            <Grid container direction="column">
                                <Grid item style={{backgroundColor: '#919191'}}>
                                            <img src={m.thumbnail} style={{width: "100%", height: "100%"}}/>
                                </Grid>
                                <Grid item>
                                    <Typography gutterBottom variant="h5" style={{textAlign: "left", margin: '20px'}}>
                                        {m.title}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography style={{textAlign: "left", margin: '0 20px'}}>
                                        {m.short_description}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Link>
                    </ButtonBase>
                </Paper>
            </div>
        });

        return (
            <div className='wrapper'>
                {
                    this.state.isLoading ? 
                    <img src="https://s3-ap-northeast-1.amazonaws.com/togo.static/img/loading.gif" /> 
                    :
                    <div className={classes.boxlist}>
                        {productsList}
                    </div>
                }
            </div>
        );
    }
}


export default compose(withStyles(styles))(ProductsBox);

/*
style={{backgroundColor: '#414141', textAlign: "left", margin: '20px'}}
style={{backgroundColor: '#919191', textAlign: "left", margin: '0 20px'}}
*/

/*
<Card className={classes.card}>
    <Link to={"/products/" + m.id} style={{ textDecoration: 'none' }} >
        <CardActionArea>
            <CardMedia
                className={classes.media}
                image="../Home/img1.png"
                title=""
            />
            <CardContent className={classes.content}>
                <Typography gutterBottom variant="h5" component="h2" className={classes.text}>
                    {m.title}
                </Typography>
                <Typography component="p" className={classes.text} >
                    {m.description}
                </Typography>
            </CardContent>
        </CardActionArea>
    </Link>
</Card>
*/
