import React, {Component} from 'react';
import {connect} from "react-redux"
import {Link} from 'react-router-dom'
import {withStyles} from '@material-ui/core/styles';
import {withRouter} from 'react-router';

import {LOGIN_URL} from "../../config/constants";

import {Grid, IconButton, Paper, Typography} from '@material-ui/core';

import {Bookmark, BookmarkBorder, PlayCircleOutline} from '@material-ui/icons';


const mapStateToProps = state => {
    return {
        auth: state.auth.initialState
    }
};

const styles = {
    root: {
        width: '100%',
        margin: '5px 0'
    },
    PlayCircle: {
        margin: 'auto',
    },
    PlayCircleIcon: {
        fontSize: '40px',
    },
};


class CategoriesList extends Component {

    onClickLogin = () => {
        window.location.assign(LOGIN_URL + '?redirect_url=' + window.location.href)
    }

    render() {
        const {classes} = this.props
        const auth = this.props.auth.auth

        //再生時間の変換関数
        function pad(n, width, z) {
            z = z || '0';
            n = n + '';
            return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
        }

        function duration(time) {
            var min = pad(Math.floor(time / 60), 2);
            var sec = pad(time % 60, 2);
            return min + ':' + sec;
        }

        //productのデータが入っている
        const product_id = this.props.product_id
        const isLoading = this.props.isLoading
        const categories = Array.isArray(this.props?.categories) ? this.props?.categories : []

        const categoriesList = categories.map((n, cidx) => {
            const categoryInfo = n.categoryInfo
            const videosList = n.videosList
            return <div key={cidx} style={{margin: "30px 0"}}>
                <Typography gutterBottom variant="h6" style={{textAlign: "left"}}>
                    {categoryInfo.title}
                </Typography>
                {
                    videosList.map((n, vidx) => {
                        const m = n.video
                        const categoryVideo_id = n["categoryVideo"]["id"]
                        console.log(categoryVideo_id)
                        return <div className={classes.root} key={vidx}>
                            <Paper style={{boxShadow: '0 3px 5px 2px #D9D8D8'}}>
                                <Grid container>
                                    <Grid item sm={3} xs={12}
                                          style={{backgroundColor: "#707070"}}
                                    >
                                        <img src={m.picture} style={{width: "100%", height: "100%"}}/>
                                    </Grid>
                                    <Grid container item sm={6} xs={6}
                                          style={{paddingLeft: "20px"}}
                                          direction="row"
                                          justify="flex-start"
                                          alignItems="center">
                                        <Grid item>
                                            <Typography variant="subtitle1" style={{textAlign: "left"}}>
                                                {m.title}
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography style={{textAlign: "left"}}>
                                                {/* {m.description} */}複素数体であれば、任意のCM-タイプの A
                                                は、実際nition)を持っている。自己準同型環の可能なタイプは、対合（ロサチの対合（英語版）(Rosati
                                                involution）をもつ環として既に分類されていて・・・
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container item sm={3} xs={6}
                                          direction="row"
                                          justify="center"
                                          alignItems="center"
                                    >
                                        <Grid item>
                                            <Typography component="h6" className={classes.time}>
                                                {duration(m.duration)}
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            {
                                                auth.isLogin ?
                                                    <Link to={`/products/${product_id}/learn/lecture/${m.id}`}
                                                          className={classes.PlayCircle}>
                                                        <IconButton className={classes.PlayCircle}>
                                                            <PlayCircleOutline className={classes.PlayCircleIcon}/>
                                                        </IconButton>
                                                    </Link>
                                                    :
                                                    <IconButton className={classes.PlayCircle} onClick={() => {
                                                        this.onClickLogin()
                                                    }}>
                                                        <PlayCircleOutline className={classes.PlayCircleIcon}/>
                                                    </IconButton>
                                            }
                                        </Grid>
                                        <Grid item>
                                            {auth.isLogin ?
                                                <div style={{display: 'flex'}}>
                                                    {
                                                        m.bookmarked ?
                                                            <IconButton className={classes.PlayCircle}
                                                                        onClick={() => {
                                                                            this.props.switchBookmark(cidx, vidx, m.bookmarked, categoryVideo_id)
                                                                        }}
                                                                        disabled={isLoading}>
                                                                <Bookmark className={classes.PlayCircleIcon}/>
                                                            </IconButton>
                                                            :
                                                            <IconButton className={classes.PlayCircle}
                                                                        onClick={() => {
                                                                            this.props.switchBookmark(cidx, vidx, m.bookmarked, categoryVideo_id)
                                                                        }}
                                                                        disabled={isLoading}>
                                                                <BookmarkBorder className={classes.PlayCircleIcon}/>
                                                            </IconButton>
                                                    }
                                                </div>
                                                :
                                                <IconButton className={classes.PlayCircle} onClick={() => {
                                                    this.onClickLogin()
                                                }}>
                                                    <BookmarkBorder className={classes.PlayCircleIcon}/>
                                                </IconButton>
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </div>
                    })
                }
            </div>
        })
        return (
            <div>
                {
                    isLoading ?
                        <img src="https://s3-ap-northeast-1.amazonaws.com/togo.static/img/loading.gif"/>
                        :
                        <div>
                            {categoriesList}
                        </div>

                }
            </div>
        );
    }
}

export default withRouter(connect(mapStateToProps)(withStyles(styles)(CategoriesList)))

/*
onClickLogin = async () => {
    let result = checkUrlToken()
    if (!result) {
        if (!localStorage.getItem('token')) {
            window.location.assign(LOGIN_URL + '?redirect_url=' + window.location.href)
        }}
    refreshToken()
}
*/

/*
<Card className={classes.lecture}>
<CardMedia
    className={classes.media}
    image={m.picture}
    title=""
/>
<div className={classes.details}>
    <CardContent className={classes.content}>
        <Typography gutterBottom variant="h6" component="h2" className={classes.text}>
            {m.title}
        </Typography>
        <Typography component="p" className={classes.text}>
            {m.description}
        </Typography>
    </CardContent>
    <Typography component="h6" className={classes.time}>
        {m.duration}
    </Typography>
    <Link to={`/products/${product_id}/learn/lecture/${m.video_id}`} className={classes.PlayCircle}>
        <IconButton className={classes.PlayCircle}>
            <PlayCircleOutline className={classes.PlayCircleIcon} />
        </IconButton>
    </Link>
    { auth.isLogin ?
        <div className={classes.details}>
            {
                m.bookmarked ?
                    <IconButton className={classes.PlayCircle}
                        onClick={() => { this.switchBookmark(cidx, vidx, m.bookmarked, m.categoryVideo_id) }}
                        disabled={isLoading}>
                        <Bookmark className={classes.PlayCircleIcon} />
                    </IconButton>
                    :
                    <IconButton className={classes.PlayCircle}
                        onClick={() => { this.switchBookmark(cidx, vidx, m.bookmarked, m.categoryVideo_id) }}
                        disabled={isLoading}>
                        <BookmarkBorder className={classes.PlayCircleIcon} />
                    </IconButton>
            }
        </div>
        :
        <IconButton className={classes.PlayCircle} onClick={() => { this.onClickLogin() }}>
            <BookmarkBorder className={classes.PlayCircleIcon} />
        </IconButton>
    }
</div>
</Card>
*/