import { store } from "../store";
import axios from "axios";
import { FETCH_BOOKMARK_LIST, FETCH_BOOKMARK_LIST_DONE, GET_BOOKMARK_LIST } from '../constants/actionTypes';
import { GET_BOOKMARKS, POST_BOOKMARK, DELETE_BOOKMARK } from "../config/apiUrl";

/*
export const getBookmarkList = () => {
    const { dispatch } = store
    dispatch({ type: FETCH_BOOKMARK_LIST })
    axios.get(GET_BOOKMARKS()
    ).then(async res => {
        dispatch({
            type: GET_BOOKMARK_LIST,
            bookmarkList: res.data
        })
    }).then(function(){
        dispatch({ type: FETCH_BOOKMARK_LIST_DONE })
    }).catch(e => {
        console.log(e)
    })
}
*/

export const onAddBookmark = (categoryVideo_id) => {
    axios.post(POST_BOOKMARK(categoryVideo_id), {set: true}
    ).catch(e => {
        console.log(e)
    })
}
export const onDeleteBookmark = (categoryVideo_id) => {
    axios.delete(DELETE_BOOKMARK(categoryVideo_id), {set: true}
    ).catch(e => {
        console.log(e)
    })
}