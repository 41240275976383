import React, { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom'

import Appbar from './appbar'
import DeleteDialog from './DeleteDialog'

import Typography from '@material-ui/core/Typography';
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { GET_ALL_PRODUCTS_ADMIN , DELETE_CATEGORY, POST_PRODUCT } from "../../config/apiUrl";


const styles = theme => ({
    root: {
        width: '80%',
        margin: "auto",
        overflowX: 'auto',
    },
    table: {
        minWidth: '50%'
    },
    form: {
        padding: 30
    },
    deleteButton: {
        color: '#FDFDFD',
        backgroundColor: '#C77373',
        border: '0px solid #C77373',
        borderRadius: 4,
    },
    button: {
        color: '#FDFDFD',
        backgroundColor: '#8BAFED',
        borderRadius: 4,
    },
    err: {
        color: 'red'
    },
    header: {
        width: '80%',
        paddingLeft: '20px',
        paddingBottom: '5px',
        paddingTop: '5px',
        margin: 'auto',
        marginTop: '5px',
        textAlign: 'left',
        backgroundColor: '#707070',
        borderRadius: 4,
        color: '#F8F8F8',
        overflowX: 'auto',
    },
});

class Products extends Component {
    constructor(props) {
        super(props);
        this.state = {
            products: [],
            openProductModal: false,
            isLoading: true,
            err: false,
        }
        this.reload = this.reload.bind(this);
    }

    componentDidMount() {
        this.reload();
    }

    reload() {
        axios.get(GET_ALL_PRODUCTS_ADMIN)
            .then(res => {
                this.setState({ 
                    products: res.data,
                    isLoading: false
             })
                console.log(this.state.products)
            })
            .catch((error) => console.log(error))
    }

    onAdd() {
        axios.post(POST_PRODUCT(),{})
            .then(res => {
                this.reload();
            })
            .catch((error) => console.log(error))
    }

    onDelete(id) {
        axios.delete(DELETE_CATEGORY(id))
        .then(res => {
            this.setState({openProductModal: false})
            this.reload();
        })
        .catch((error) => console.log(error))
    }

    onOpenProductModal(){
        console.log("aaa")
        this.setState({openProductModal: true})
    }

    handleClose = () => {
        this.setState({ 
            openProductModal: false
        });
    };


    render() {
        const { classes } = this.props;
        const products = this.state.products
        return (
            <div>
                <Appbar />
                    {
                        this.state.isLoading ? 
                        <img src="https://s3-ap-northeast-1.amazonaws.com/togo.static/img/loading.gif" /> :
                    <div>
                        <Typography variant="h6" className={classes.header}>
                            プロダクト一覧
                        </Typography>
                        <Paper className={classes.root}>
                            <Table className={classes.table}>
                                <TableHead>
                                <TableRow>
                                    <TableCell>ID</TableCell>
                                    <TableCell align="center">タイトル</TableCell>
                                    <TableCell align="center">状況</TableCell>
                                    <TableCell align="center"></TableCell>
                                    <TableCell align="center"></TableCell>
                                </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        products ? products.map((n)=>{
                                            return <TableRow>
                                                <TableCell key={"categories_" + n.id} component="th" scope="row">
                                                    {n.id}
                                                </TableCell>
                                                <TableCell align="center">{n.title}</TableCell>
                                                <TableCell align="center">
                                                    {
                                                        n.avail_flg===1 ? 
                                                        <span>
                                                            配信中
                                                        </span>
                                                        :
                                                        <span>
                                                            停止中
                                                        </span>
                                                    }
                                                </TableCell>
                                                <TableCell align="center">
                                                    <Link to={"/admin/categories/" + n.id} style={{ textDecoration: 'none' }}>
                                                        <Button
                                                        className={classes.button}
                                                        variant="raised"
                                                        color="primary"
                                                        >
                                                            編集
                                                        </Button>
                                                    </Link>
                                                </TableCell>
                                                <TableCell align="center">
                                                    <Button
                                                    className={classes.deleteButton}
                                                    onClick={e => this.onOpenProductModal()}
                                                    >
                                                        削除
                                                    </Button>
                                                    <DeleteDialog 
                                                        title={n.title}
                                                        openModal={this.state.openProductModal}
                                                        onDelete={() => { this.onDelete(n.id) }}
                                                        handleClose={() => { this.handleClose() }}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        }) : null
                                    }
                                </TableBody>
                            </Table>
                            <Button 
                            type="button" 
                            style={{width:"100%", marginTop:"5px"}}
                            variant="outlined" color="primary"
                            onClick={e => this.onAdd()}>
                                追加
                            </Button>
                        </Paper>
                    </div>
                    }
            </div>
        );
    }
}

export default withStyles(styles)(Products);