import React, { Component } from 'react';
import { Link } from 'react-router-dom'

import { withStyles } from "@material-ui/core/styles";
import { withRouter } from 'react-router';
import { connect } from 'react-redux'
import axios from 'axios';

import Appbar from './appbar'
import './AdminStyle.css';

import Typography from '@material-ui/core/Typography';
import Button from "@material-ui/core/Button";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { POST_CATEGORY, PUT_PRODUCT, DELETE_RELATION } from "../../config/apiUrl";


const styles = theme => ({
    root: {
        width: '80%',
        margin: "auto",
        overflowX: 'auto',
    },
    section: {
        marginBottom: '30px'
    },
    formControl: {
        display: 'flex',
        paddingTop: '5px',
        paddingBottom: '5px',
        alignItems: 'center',
    },
    input: {
        width: '100%',
        borderRadius: 4,
        border: '1px solid #ced4da',
        backgroundColor: '#FDFDFD',
        padding: '3px 10px',
    },
    form: {
        padding: 30,
    },
    addButton: {
        color: '#8BAFED',
        border: '1px solid #8BAFED',
        backgroundColor: '#FDFDFD',
        borderRadius: 4,
    },
    deleteButton: {
        color: '#C77373',
        backgroundColor: '#FDFDFD',
        border: '1px solid #C77373',
        borderRadius: 4,
    },
    button: {
        color: '#FDFDFD',
        backgroundColor: '#8BAFED',
        borderRadius: 4,
    },
    table: {
        tableLayout: 'auto'
    },
    err: {
        color: 'red'
    },
    header: {
        paddingLeft: '20px',
        paddingBottom: '5px',
        paddingTop:'5px',
        marginBottom: '10px',
        textAlign: 'left',
        width: '100%',
        backgroundColor: '#707070', 
        borderRadius: 4,
        color: '#F8F8F8'
    },
    icon: {
        margin: 'auto'
    }
});

const CustomTableCell = withStyles(theme => ({
    head: {
      backgroundColor: '#F8F8F8',
      color: '#333333',
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);
  

class ManageParents extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {},
            isLoading: false,
            error: [],
            openCategoryModal: false,
            openCategoryDeleteModal: false,
            isSync: false
        }
    }

    onOpenCategoryModal(){
        this.setState({openCategoryModal: true})
    };

    openCategoryDeleteModal(){
        this.setState({openCategoryDeleteModal: true})
    }

    handleClose = () => {
        this.setState({ 
            openCategoryModal: false,
            openCategoryDeleteModal: false,
        });
      };

    onAdd() {
        const parents = this.props.parents
        const order = parents.length+1
        console.log(this.props.pid)
        axios.post(POST_CATEGORY(),{
            pid: this.props.pid,
            order: order,
        }).then(() => {
            const pid = this.props.pid
            this.props.reload(pid);
        }).catch((error) => console.log(error))
    }

    onDelete(cid) {
        const pid = this.props.pid
        axios.delete(DELETE_RELATION(cid,pid),{set:true}
        ).then(() => {
            const pid = this.props.pid
            this.props.reload(pid);
        }).catch((error) => console.log(error))
    }

    onCommit(){
        const data = {
            "product": this.state. data[0]
        }

        this.setState({
            isLoading:true, error:[]
        })

        const product_id = this.props.match.params.product_id
        axios.put(PUT_PRODUCT(product_id),{data})
        .then(res => {
            this.setState({ isLoading: false })
            this.props.history.push("/admin/products/" +  product_id)
        })
        .catch(error => {
            this.setState({ loading: false, error: ["更新に失敗しました"] })
        })

    }

    render() {
        // const { params } = this.params.match;
        const { classes } = this.props;
        const pid = this.props.pid
        const categories = this.props.categories;
        const parents = this.props.parents;
        return (
            <div>
                <Appbar />
                    {
                        this.state.isLoading ? 
                        <img src="https://s3-ap-northeast-1.amazonaws.com/togo.static/img/loading.gif" /> :
                        <form className={classes.form}>
                            <div className={classes.section}>
                                <Typography variant="h6" className={classes.header}>
                                    {this.props.categoryType}一覧
                                </Typography>
                                { parents.length > 0 ?
                                <Table className={classes.table}>
                                    <TableHead>
                                    <TableRow>
                                        <CustomTableCell>ID</CustomTableCell>
                                        <CustomTableCell>タイトル</CustomTableCell>
                                        <CustomTableCell align="center"></CustomTableCell>
                                    </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        { 
                                            parents.map((n)=>{
                                                return <TableRow>
                                                <CustomTableCell>{n.parent_id}</CustomTableCell>
                                                <CustomTableCell component="th" scope="row">{n.title}</CustomTableCell>
                                                <CustomTableCell align="center">
                                                    <Link to={"/admin/categories/"+n.parent_id} style={{ textDecoration: 'none' }}>
                                                        <Button
                                                        className={classes.button}
                                                        >
                                                            詳細
                                                        </Button>
                                                    </Link>
                                                </CustomTableCell>
                                                {/* <CustomTableCell align="center">
                                                    <Button
                                                    className={classes.deleteButton}
                                                    onClick={ e => this.openCategoryDeleteModal() }
                                                    >
                                                        削除
                                                    </Button>
                                                    <DeleteDialog
                                                        title={n.title}
                                                        openModal={this.state.openCategoryDeleteModal}
                                                        onDelete={() => { this.onDelete(n.id) }}
                                                        handleClose={() => { this.handleClose() }}
                                                    />
                                                </CustomTableCell> */}
                                                </TableRow>
                                            })
                                        }
                                    </TableBody>
                                </Table>
                                : <Typography>{this.props.categoryType}はありません</Typography>
                                }
                            </div>
                        </form>
                    }
            </div>
        );
    }
}

export default withRouter(connect()(withStyles(styles)(ManageParents)))