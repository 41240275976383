import React, {Component} from 'react';
import {Link} from 'react-router-dom';

import {withStyles} from '@material-ui/core/styles';
import {withRouter} from 'react-router';
import {connect} from 'react-redux';
import axios from 'axios';

import Appbar from './appbar';
import ConfirmationDialog from './ConfirmationDialog';
import './AdminStyle.css';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import Divider from '@material-ui/core/Divider';
import AddIcon from '@material-ui/icons/Add';

import {
    DELETE_RELATION,
    POST_CATEGORY,
    PUT_CATEGORY_CATEGORY_RELATION,
} from '../../config/apiUrl';

const styles = theme => ({
    root: {
        width: '80%',
        margin: "auto",
        overflowX: 'auto',
    },
    section: {
        marginBottom: '30px'
    },
    formControl: {
        display: 'flex',
        paddingTop: '5px',
        paddingBottom: '5px',
        alignItems: 'center',
    },
    input: {
        width: '100%',
        borderRadius: 4,
        border: '1px solid #ced4da',
        backgroundColor: '#FDFDFD',
        padding: '3px 10px',
    },
    form: {
        padding: 30,
    },
    addButton: {
        color: '#8BAFED',
        border: '1px solid #8BAFED',
        backgroundColor: '#FDFDFD',
        borderRadius: 4,
    },
    deleteButton: {
        color: '#C77373',
        backgroundColor: '#FDFDFD',
        border: '1px solid #C77373',
        borderRadius: 4,
    },
    button: {
        color: '#FDFDFD',
        backgroundColor: '#8BAFED',
        borderRadius: 4,
    },
    table: {
        tableLayout: 'auto'
    },
    err: {
        color: 'red'
    },
    header: {
        paddingLeft: '20px',
        paddingBottom: '5px',
        paddingTop: '5px',
        marginBottom: '10px',
        textAlign: 'left',
        width: '100%',
        backgroundColor: '#707070',
        borderRadius: 4,
        color: '#F8F8F8',
    },
    icon: {
        margin: 'auto'
    }
});

const CustomTableCell = withStyles(theme => ({
    head: {
      backgroundColor: '#F8F8F8',
      color: '#333333',
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);


class ManageChildren extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            error: [],
            openConfirmationModal: false,
            isDoing: false,
            categories : this.props.categories,
            children : this.props.children,
            openCategoryModal: false,
            openCategoryDeleteModal: false,
            openVideosOfChildCategoryModal: false,
            isSync: false
        }
    }

    onOpenCategoryModal(){
        this.setState({openCategoryModal: true})
    };

    openCategoryDeleteModal(){
        this.setState({openCategoryDeleteModal: true})
    }

    onOpenVideosOfChildCategoryModal(){
        this.setState({openVideosOfChildCategoryModal: true})
    };

    handleClose = () => {
        this.setState({
            openCategoryModal: false,
            openConfirmationModal: false,
            openCategoryDeleteModal: false,
            openVideosOfChildCategoryModal: false,
        });
      };

    onAdd() {
        const children = this.props.children
        const order = children.length+1
        console.log(this.props.pid)
        axios.post(POST_CATEGORY(),{
            pid: this.props.pid,
            order: order,
        }).then(() => {
            const pid = this.props.pid
            this.props.reload(pid);
        }).catch((error) => console.log(error))
    }

    onDelete(cid) {
        const pid = this.props.pid
        axios.delete(DELETE_RELATION(cid,pid),{set:true}
        ).then(() => {
            const pid = this.props.pid
            this.props.reload(pid);
        }).catch((error) => console.log(error))
    }

    swapFront(idx){
        const children = this.state.children
        const t = children[idx]
        if(children.length - 1 <= idx){
            return ;
        }
        children[idx] = children[idx + 1]
        children[idx + 1] = t
        this.setState({ children })
        console.log(children)
    }

    swapBack(idx){
        const children = this.state.children
        const t = children[idx]
        if(idx <= 0){
            return;
        }
        children[idx] = children[idx -1]
        children[idx -1] = t
        this.setState({ children })
        console.log(children)
    }

    switchVideBelonging(cidx, belong, title){
        //belogの反転
        const t = this.state.categories
        t[cidx]["belong"] = !belong;
        const child_id = t[cidx]["id"]
        if(!belong){
            //childrenに追加
            const x = this.state.children ? this.state.children : {}
            const number = x.length
            const s = t[cidx]
            x[number] = {categoryInfo: {child_id: child_id, title: title}, videosList: []}
            this.setState({ categories: t , children: x})
            console.log(x)
        }else{
            //childrenからの削除
            const x = this.state.children ? this.state.children : {}
            x.some(function(v, i){if(v["categoryInfo"].child_id==child_id) x.splice(i,1);})
            this.setState({ categories: t , children: x})
            console.log(x)
        }
    }

    onCommit() {
        const children = this.state.children
        const pid = this.props.match.params.category_id
        this.setState({
            error:[],
            openConfirmationModal: true,
            isDoing: true
        })
        axios.post(PUT_CATEGORY_CATEGORY_RELATION(pid), {children})
        .then(res => {
            this.setState({ isDoing: false })
            const pid = this.props.match.params.category_id
            this.props.history.push("/admin/categories/" + pid)
        }).catch((error) => console.log(error))
    }

    render() {
        // const { params } = this.params.match;
        const { classes } = this.props;
        const pid = this.props.pid
        const categories = this.state.categories;
        const children = this.state.children;
        return (
            <div>
                <Appbar />
                    {
                        this.state.isLoading
                            ?
                            <img
                                src="https://s3-ap-northeast-1.amazonaws.com/togo.static/img/loading.gif"/>
                            :
                            <form className={classes.form}>
                                <div className={classes.section}>
                                    <Typography variant="h6"
                                                className={classes.header}>
                                        {this.props.categoryType}一覧
                                    </Typography>
                                    {children.length > 0 ?
                                        <Table className={classes.table}>
                                            <TableHead>
                                                <TableRow>
                                        <CustomTableCell>ID</CustomTableCell>
                                        <CustomTableCell>タイトル</CustomTableCell>
                                        <CustomTableCell align="center"></CustomTableCell>
                                        <CustomTableCell align="center"></CustomTableCell>
                                        <CustomTableCell align="center"></CustomTableCell>
                                    </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            children.map((m, idx)=>{
                                                const n = m["categoryInfo"]
                                                const v = m["videosList"]
                                                return <TableRow key={idx}>
                                                    <CustomTableCell>{n.child_id}</CustomTableCell>
                                                    <CustomTableCell component="th" scope="row">{n.title}</CustomTableCell>
                                                    <CustomTableCell align="center">
                                                        <Link to={"/admin/categories/"+n.child_id} style={{ textDecoration: 'none' }}>
                                                            <Button
                                                            className={classes.button}
                                                            >
                                                                詳細
                                                            </Button>
                                                        </Link>
                                                    </CustomTableCell>
                                                    <CustomTableCell align="center">
                                                        <Button
                                                            onClick={e=>this.swapBack(idx)}
                                                        >
                                                            ▲
                                                        </Button>
                                                    </CustomTableCell>
                                                    <CustomTableCell align="center">
                                                        <Button
                                                            onClick={e => this.swapFront(idx)}
                                                        >
                                                            ▼
                                                        </Button>
                                                    </CustomTableCell>
                                                </TableRow>
                                            })
                                        }
                                    </TableBody>
                                </Table>
                                : <Typography>{this.props.categoryType}はありません</Typography>
                                }
                                <div style={{display: "flex", justifyContent: "flex-end", marginTop: '5px', marginBottom: '5px'}}>
                                    <Button
                                        className={classes.addButton}
                                        style={{float: 'right', width: '40%'}}
                                        onClick={e => this.onOpenCategoryModal()}>
                                        カテゴリーを追加・削除する
                                    </Button>
                                </div>
                                <div style={{display: "flex", justifyContent: "flex-end"}}>
                                    <Button
                                        className={classes.button}
                                        style={{float: 'right', width: '40%'}}
                                        onClick={e => this.onCommit()}>
                                        更新
                                    </Button>
                                </div>
                                <ConfirmationDialog
                                    isDoing={this.state.isDoing}
                                    openConfirmationModal={this.state.openConfirmationModal}
                                    isDoingComment={"更新しています"}
                                    isDoneComment={"更新が完了しました"}
                                    handleClose={() => { this.handleClose() }}
                                />
                                <Dialog
                                    // open={true}
                                    open={this.state.openCategoryModal}
                                    onClose={this.handleClose}
                                    scroll="paper"
                                    fullWidth={true}
                                    style={{minWidth: "70%"}}
                                >
                                    <DialogTitle>
                                        追加するカテゴリーを選択する
                                    </DialogTitle>
                                    <DialogContent>
                                        <FormControl>
                                            <FormGroup>
                                                {
                                                    categories ? categories.map((element,cidx) => {
                                                        // console.log(categories)
                                                        return <div key={"vid_" + cidx}>
                                                            <div>
                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox
                                                                            checked={element.belong}
                                                                            onChange={(e) => {
                                                                                this.switchVideBelonging(
                                                                                    cidx,
                                                                                    element.belong,
                                                                                    element.title);
                                                                            }}
                                                                        />
                                                                    }
                                                                    label={element.title}
                                                                />
                                                            </div>
                                                            <div><Divider /></div>
                                                        </div>
                                                    }) : null
                                                }
                                        </FormGroup>
                                        </FormControl>
                                        <div><Divider /></div>
                                        <div style={{display: "flex",justifyContent: "space-between"}}>
                                            <div style={{textAlign: "left"}}>
                                                <Button
                                                    onClick={e => this.onCommit()}
                                                >
                                                    <AddIcon />
                                                    <Typography>
                                                        更新
                                                    </Typography>
                                                </Button>
                                            </div>
                                            <div style={{textAlign: "right"}}>
                                                <Button
                                                    onClick={e => this.onAdd()}
                                                >
                                                    <AddIcon />
                                                    <Typography>
                                                        新しいカテゴリーを追加
                                                    </Typography>
                                                </Button>
                                            </div>
                                        </div>
                                    </DialogContent>
                                </Dialog>
                            </div>
                        </form>
                    }
            </div>
        );
    }
}

export default withRouter(connect()(withStyles(styles)(ManageChildren)))
