import axios from "axios";
import { ACCESS_TOKEN_KEY } from "../config/constants";

export default () => {
    //REQUEST
    axios.interceptors.request.use(
        (config) => {
            console.log(`---- ${config.method.toUpperCase()} ${config.url} ----`);
            console.log(config);
            const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);
            if (accessToken) {
                config.headers['Authorization'] = `Bearer ${accessToken}`;
            }
            return config;
        },
        error => {
            if (error && error.request) {
                console.log(error);
                console.log(error.request);
            }
            return Promise.reject(error);
        });

    //RESPONSE
    axios.interceptors.response.use(
        function (response) {
            return response;
        },
        async function (error) {
            if (!error.response) {
                alert("Please check your internet connection.")
                return
            }
            return Promise.reject(error);
        }
    );
}