import React, { Component } from 'react';
import axios from "axios";
import { connect } from "react-redux"
import { Link } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles';
import { compose } from "redux";

import { LOGIN_URL } from "../../config/constants";
import { checkUrlToken, refreshToken } from "../../actions/user";

import { getBookmarkList } from '../../actions/bookmarks'

import MenuAppBar from './MenuAppBar/MenuAppBar';
import Footer from './Footer/Footer';

import {
    IconButton, Typography, Grid,
    Paper
} from '@material-ui/core';

import {
    PlayCircleOutline, Bookmark,
    BookmarkBorder,
    DepartureBoardOutlined
} from '@material-ui/icons';

import { GET_BOOKMARKS } from "../../config/apiUrl";
import { onAddBookmark, onDeleteBookmark } from "../../actions/bookmarks";

const mapStateToProps = state => {
    return {
        bookmarkList: state.bookmarkList,
        auth: state.auth.initialState
    }
};

const styles = {
    wrapper: {
      maxWidth: '1100px',
      margin: '10px auto',
      padding: "0 30px"
    },
    root: {
        width: '100%',
        margin: '5px 0'
      },
    PlayCircle: {
        margin: 'auto',
    },
    PlayCircleIcon: {
        fontSize: '40px',
    },
};


class Bookmarks extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            isLoading: false,
            error: [],
        }
    }

    componentDidMount() {
        this.setState({isLoading: true})
        console.log(axios.get(GET_BOOKMARKS()))
        axios.get(GET_BOOKMARKS()
        ).then(res => {
            console.log(res)
            this.setState({
                isLoading: false,
                data: res.data
            })
            console.log(this.state.data)
        }).catch(e => {
            console.log(e)
        })
    }

    async switchBookmark(bidx, idx, vidx, bookmarked, categoryVideo_id){
        const t = this.state.data
        t[bidx]["categoriesList"][idx]["videosList"][vidx]["video"]["bookmarked"] = !bookmarked
        try {
            this.setState({
                data: t,
                isLoading: true
            })
            if (bookmarked) {
                await onDeleteBookmark(categoryVideo_id)
            } else {
                await onAddBookmark(categoryVideo_id)
            }
        }catch(e) {
            console.log(e)
        }finally{
            this.setState({isLoading: false})
        }
    }

    render() {
        const { classes } = this.props
        const isLoading = this.state.isLoading
        //再生時間の変換関数
        function pad(n, width, z) {
            z = z || '0';
            n = n + '';
            return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
          }
        function duration(time){ 
            var min = pad(Math.floor(time / 60), 2);
            var sec = pad(time % 60, 2);
            return min + ':' + sec;
        }
        //bookmarkListのデータが入っている
        const data = this.state.data
        const bookmarks = data.map((m, bidx)=>{
            const productInfo = m.categoryInfo
            const product_id = productInfo.id
            const categoriesList = m.categoriesList
            console.log(productInfo)
            return <div key={bidx}>
                <Typography gutterBottom variant="h5" style={{textAlign: "left"}}>
                    {productInfo.title}
                </Typography>
                {
                    categoriesList.map((n, idx)=>{
                        const videos = n.videosList
                        return <div key={idx} style={{margin: "30px 0"}}>
                            <Typography gutterBottom variant="h6" style={{textAlign: "left"}}>
                                {n.categoryInfo.title}
                            </Typography>
                            {
                                videos.length > 0 ?
                                    videos.map((l, vidx)=>{
                                        const m = l.video
                                        const categoryVideo_id = l.categoryVideo.id
                                        return <div className={classes.root}>
                                                <div key={vidx}>
                                                    <Paper style={{boxShadow: '0 3px 5px 2px #D9D8D8'}}>
                                                        <Grid container>
                                                            <Grid item sm={3} xs={12}
                                                                style={{backgroundColor: "#707070"}}
                                                            >
                                                                <img src={m.picture} style={{width: "100%", height: "100%"}}/>
                                                            </Grid>
                                                            <Grid container item sm={6} xs={12}
                                                                style      = {{paddingLeft: "20px"}}
                                                                direction  = "row"
                                                                justify    = "flex-start"
                                                                alignItems = "center">
                                                                <Grid item>
                                                                    <Typography variant="subtitle1" style={{textAlign: "left"}}>
                                                                        {m.title}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Typography style={{textAlign: "left"}}>
                                                                        {/* {m.description} */}複素数体であれば、任意のCM-タイプの A は、実際nition)を持っている。自己準同型環の可能なタイプは、対合（ロサチの対合（英語版）(Rosati involution）をもつ環として既に分類されていて・・・
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid container item sm={3} xs={12}
                                                                direction  = "row"
                                                                justify    = "center"
                                                                alignItems = "center"
                                                            >
                                                                <Grid item>
                                                                    <Typography component="h6" className={classes.time}>
                                                                        {duration(m.duration)}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Link to={`/products/${product_id}/learn/lecture/${m.id}`} className={classes.PlayCircle}>
                                                                        <IconButton className={classes.PlayCircle}>
                                                                            <PlayCircleOutline className={classes.PlayCircleIcon} />
                                                                        </IconButton>
                                                                    </Link>
                                                                </Grid>
                                                                <Grid item>
                                                                    {
                                                                        m.bookmarked ? 
                                                                        <div style={{display: "flex"}}>
                                                                        <IconButton className={classes.PlayCircle}
                                                                            onClick={() => { this.switchBookmark(bidx, idx, vidx, m.bookmarked, categoryVideo_id) }}
                                                                            disabled={isLoading}>
                                                                            <Bookmark className={classes.PlayCircleIcon} />
                                                                        </IconButton>
                                                                        </div>
                                                                        :
                                                                        <IconButton className={classes.PlayCircle}
                                                                            onClick={() => { this.switchBookmark(bidx, idx, vidx, m.bookmarked, categoryVideo_id) }}
                                                                            disabled={isLoading}>
                                                                            <BookmarkBorder className={classes.PlayCircleIcon} />
                                                                        </IconButton>
                                                                    }
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Paper>
                                                </div>
                                            </div>
                                    })
                                : <Typography variant="p" style={{textAlign: "left"}}>ブックマークされたビデオはありません</Typography>
                            }
                            
                        </div>
                    })
                }
            </div>
        })
        return (
            <div>
                <MenuAppBar/>
                {
                    isLoading ?
                        <img src="https://s3-ap-northeast-1.amazonaws.com/togo.static/img/loading.gif" />
                        :
                        <div>
                            <div className={classes.wrapper}>
                                {bookmarks}
                            </div>
                            <Footer/>
                        </div>

                }
            </div>
        );
    }
}
export default compose(withStyles(styles), connect(mapStateToProps))(Bookmarks);