import React, {Component} from 'react';
import {Link} from 'react-router-dom';

import {withStyles} from '@material-ui/core/styles';
import {withRouter} from 'react-router';
import {connect} from 'react-redux';
import axios from 'axios';

// import DeleteDialog from './DeleteDialog'
import ConfirmationDialog from './ConfirmationDialog';
import './AdminStyle.css';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import Divider from '@material-ui/core/Divider';
import AddIcon from '@material-ui/icons/Add';

import {
    DELETE_CATEGORY_VIDEO_RELATION,
    PUT_CATEGORY_VIDEO_RELATION,
    SYNC_VIDEO,
} from '../../config/apiUrl';

const styles = theme => ({
    root: {
        width: '80%',
        margin: "auto",
        overflowX: 'auto',
    },
    section: {
        marginBottom: '30px'
    },
    formControl: {
        display: 'flex',
        paddingTop: '5px',
        paddingBottom: '5px',
        alignItems: 'center',
    },
    input: {
        width: '100%',
        borderRadius: 4,
        border: '1px solid #ced4da',
        backgroundColor: '#FDFDFD',
        padding: '3px 10px',
    },
    form: {
        padding: 30,
    },
    addButton: {
        margin: "15px",
        width: '50%',
        color: '#2F4F4F',
        border: '1px solid #2F4F4F',
        backgroundColor: '#FDFDFD',
        borderRadius: 4,
    },
    addDoubleButton: {
        margin: "15px",
        width: '100%',
        color: '#2F4F4F',
        border: '1px solid #2F4F4F',
        backgroundColor: '#FDFDFD',
        borderRadius: 4,
    },
    addButton: {
        color: '#8BAFED',
        border: '1px solid #8BAFED',
        backgroundColor: '#FDFDFD',
        borderRadius: 4,
    },
    deleteButton: {
        color: '#C77373',
        backgroundColor: '#FDFDFD',
        border: '1px solid #C77373',
        borderRadius: 4,
    },
    button: {
        color: '#FDFDFD',
        backgroundColor: '#8BAFED',
        borderRadius: 4,
    },
    table: {
        tableLayout: 'auto'
    },
    err: {
        color: 'red'
    },
    header: {
        paddingLeft: '20px',
        paddingBottom: '5px',
        paddingTop: '5px',
        marginBottom: '10px',
        textAlign: 'left',
        width: '100%',
        backgroundColor: '#707070',
        borderRadius: 4,
        color: '#F8F8F8',
    },
    icon: {
        margin: 'auto'
    }
});

const CustomTableCell = withStyles(theme => ({
    head: {
      backgroundColor: '#F8F8F8',
      color: '#333333',
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);


class ManageVideos extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {},
            isLoading: false,
            error: [],
            videos : this.props.videos,
            videosList : this.props.videosList,
            openVideoModal: false,
            openConfirmationModal: false,
            isDoing: false
        }
    }

    onOpenVideoModal(){
        this.setState({openVideoModal: true})
    }

    handleClose = () => {
        this.setState({
            openVideoModal: false,
            openConfirmationModal: false,
        });
      };

    onDelete(video_id) {
        const pid = this.props.match.params.category_id
        axios.delete(DELETE_CATEGORY_VIDEO_RELATION(video_id,pid),{set:true})
            .then(() => {
                const pid = this.props.match.params.category_id
                this.reload(pid);
            })
            .catch((error) => console.log(error))
    }

    swapFront(idx){
        const videos = this.state.videos
        const t = videos[idx]
        if(videos.length - 1 <= idx){
            return ;
        }
        videos[idx] = videos[idx + 1]
        videos[idx + 1] = t
        this.setState({ videos })
        console.log(videos)
    }

    swapBack(idx){
        const videos = this.state.videos
        const t = videos[idx]
        if(idx <= 0){
            return;
        }
        videos[idx] = videos[idx -1]
        videos[idx -1] = t
        this.setState({ videos })
        console.log(videos)
    }

    switchVideBelonging(idx, vidx, belong, title){
        //belogの反転
        const t = this.state.videosList
        t[idx]["videos"][vidx]["belong"] = !belong;
        const video_id = t[idx]["videos"][vidx]["id"]
        if(!belong){
            //videosに追加
            const x = this.state.videos ? this.state.videos : {}
            const number = x.length
            x[number] = {video_id: video_id, title: title}
            this.setState({ videosList: t , videos: x})
        }else{
            //videosからの削除
            const x = this.state.videos ? this.state.videos : {}
            x.some(function(v, i){if(v.video_id==video_id) x.splice(i,1);})
            console.log(x)
            this.setState({ videosList: t ,videos: x})
        }
    }

    onCommit() {
        const videos = this.state.videos
        const pid = this.props.match.params.category_id
        this.setState({
            error:[],
            openConfirmationModal: true,
            isDoing: true
        })
        axios.post(PUT_CATEGORY_VIDEO_RELATION(pid), {videos})
        .then(() => {
            this.setState({ isDoing: false })
            const pid = this.props.match.params.category_id
            this.props.history.push("/admin/categories/" + pid)
        }).catch((error) => console.log(error))
    }

    sync() {
        console.log('動画を同期するよ')
        this.setState({openConfirmationModal: true, isDoing: true})
        axios.post(SYNC_VIDEO())
            .then(() => {
                console.log('動機を完了したよ')
                this.setState({isDoing: false})
            })
            .catch(e =>{
                console.log(e)
            })
    }

    render() {
        const { classes } = this.props;
        const pid = this.props.match.params.category_id
        const videos = this.state.videos ? this.state.videos : {};
        const videosList = this.state.videosList;
        const orderList = this.state.orderList
        return (
            <div>
                {
                    this.state.isLoading
                        ?
                        <img
                            src="https://s3-ap-northeast-1.amazonaws.com/togo.static/img/loading.gif"/>
                        :
                        <form className={classes.form}>
                            <div className={classes.section}>
                                <Typography variant="h6"
                                            className={classes.header}>
                                    ビデオ追加
                                </Typography>
                                {videos.length > 0 ?
                                    <Table className={classes.table}>
                                        <TableHead>
                                            <TableRow>
                                                <CustomTableCell>タイトル</CustomTableCell>
                                                <CustomTableCell
                                                    align="center"/>
                                                <CustomTableCell
                                                    align="center"/>
                                                <CustomTableCell
                                                    align="center"/>
                                            </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        videos.map((n, idx) =>{
                                            return <TableRow key = {idx}>
                                                <CustomTableCell>{n.id}{n.title}</CustomTableCell>
                                                <CustomTableCell style={{textAlign: 'center'}}>
                                                    <Button
                                                        className={classes.button}
                                                        target="_blank" color="inherit" href={"https://vimeo.com/manage/" + n.video_id + "/general"}
                                                    >
                                                        詳細
                                                    </Button>
                                                </CustomTableCell>
                                                <CustomTableCell style={{textAlign: 'center'}}>
                                                    <Button
                                                        onClick={e=>this.swapBack(idx)}
                                                    >
                                                        ▲
                                                    </Button>
                                                </CustomTableCell>
                                                <CustomTableCell style={{textAlign: 'center'}}>
                                                    <Button
                                                        onClick={e => this.swapFront(idx)}
                                                    >
                                                        ▼
                                                    </Button>
                                                </CustomTableCell>
                                            </TableRow>
                                        })
                                    }
                                </TableBody>
                            </Table>
                            : "ビデオはありません"
                            }
                            <div style={{display: "flex", justifyContent: "flex-end"}}>
                                <Button
                                    className={classes.addButton}
                                    style={{float: 'right', width: '20%', marginTop: '5px', marginBottom: '5px'}}
                                    onClose={this.handleClose}
                                    onClick={e => this.sync()}>
                                    編集したビデオを同期する
                                </Button>
                                <ConfirmationDialog
                                    isDoing={this.state.isDoing}
                                    openConfirmationModal={this.state.openConfirmationModal}
                                    isDoingComment={"同期しています"}
                                    isDoneComment={"同期が完了しました"}
                                    handleClose={() => { this.handleClose() }}
                                />
                                <Button
                                    className={classes.addButton}
                                    style={{float: 'right', width: '20%', marginTop: '5px', marginBottom: '5px'}}
                                    onClose={this.handleClose}
                                    onClick={e => this.onOpenVideoModal()}>
                                    ビデオを追加・削除する
                                </Button>
                            </div>
                            <div style={{display: "flex", justifyContent: "flex-end"}}>
                                <Button
                                    className={classes.button}
                                    style={{float: 'right', width: '40%'}}
                                    onClick={e => this.onCommit()}>
                                    更新
                                </Button>
                            </div>
                                <Dialog
                                // open={true}
                                    open={this.state.openVideoModal}
                                    onClose={this.handleClose}
                                    scroll="paper"
                                    fullWidth={true}
                                    style={{minWidth: "70%"}}
                                >
                                <DialogTitle>
                                    追加するビデオを選択する
                                </DialogTitle>
                                <DialogContent>
                                    {/* <DialogContentText>
                                        追加したいカテゴリーを選択する
                                    </DialogContentText> */}
                                    <FormControl>
                                        <FormGroup>
                                            {
                                                videosList ? videosList.map((m, idx) => {
                                                    const n = m.videos
                                                    return <div>
                                                        <Typography variant="h6" style={{marginBottom:"5px", marginTop:"5px"}}>
                                                            {m.title}
                                                        </Typography>
                                                            {
                                                                n ? n.map((element,vidx) => {
                                                                    return <div key={"vid_" + vidx}>
                                                                        <div>
                                                                            <FormControlLabel
                                                                                control={
                                                                                    <Checkbox
                                                                                        checked={element.belong}
                                                                                        onChange={(e) => {
                                                                                            this.switchVideBelonging(
                                                                                                idx,
                                                                                                vidx,
                                                                                                element.belong,
                                                                                                element.title);
                                                                                        }}
                                                                                    />
                                                                                }
                                                                                label={element.title}
                                                                            />
                                                                        </div>
                                                                        <div><Divider /></div>
                                                                    </div>
                                                                }) : null
                                                            }
                                                        </div>
                                                }
                                                ) : null
                                            }
                                        </FormGroup>
                                    </FormControl>
                                    <div><Divider /></div>
                                    <div style={{display: "flex",justifyContent: "space-between"}}>
                                        <div style={{textAlign: "left"}}>
                                            <Link to={"/admin/categories/"+ pid} style={{ textDecoration: 'none' }}>
                                                <Button
                                                    onClick={e => this.onCommit()}
                                                >
                                                    <AddIcon />
                                                    <Typography>
                                                        更新
                                                    </Typography>
                                                </Button>
                                            </Link>
                                        </div>
                                        <div style={{textAlign: "right"}}>
                                            <Button
                                                target="_blank" color="inherit" href='https://vimeo.com/'
                                            >
                                                <AddIcon />
                                                <Typography>
                                                    新しいビデオをvimeoで作成する
                                                </Typography>
                                            </Button>
                                    </div>
                                    </div>
                                </DialogContent>
                            </Dialog>
                            <ConfirmationDialog
                                isDoing={this.state.isDoing}
                                openConfirmationModal={this.state.openConfirmationModal}
                                isDoingComment={"更新しています"}
                                isDoneComment={"更新が完了しました"}
                                handleClose={() => { this.handleClose() }}
                            />
                        </div>
                    </form>
                }
            </div>
        );
    }
}

export default withRouter(connect()(withStyles(styles)(ManageVideos)))
//ビデオの削除用
{/* <CustomTableCell style={{textAlign: 'center'}}>
    <Button
    className={classes.deleteButton}
    onClick={e => this.onVideoProductModal()}
    >
        削除
    </Button>
    <DeleteDialog
        title={n.title}
        openProductModal={this.state.openVideoModal}
        onDelete={() => { this.onDelete(n.id) }}
        handleClose={() => { this.handleClose() }}
    />
</CustomTableCell> */}

//ビデオ追加のmodal表示をListを用いたバージョン
// {
//     videosList ? videosList.map((m, idx) => {
//         const n = m.videos
//         return <List key={"cid_" + idx}>
//             {/* <ListItem button onClick={this.onExpansion}> */}
//             <ListItem>
//                 <ListItemText inset primary={m.title} />
//                 {/* {this.state.open ? <ExpandLess /> : <ExpandMore />} */}
//             </ListItem>
//             {/* <Collapse in={this.state.open} timeout="auto" unmountOnExit>
//             </Collapse> */}
//                 <List component="div" disablePadding>
//                     {
//                         n ? n.map((element,vidx) => {
//                             return <div key={"vid_" + vidx}>
//                                 <div style={{display:"flex"}}>
//                                     <ListItem alignItems="flex-start">
//                                         <ListItemIcon className={classes.icon}>
//                                             <Checkbox
//                                                 checked={element.belong}
//                                                 onChange={(e)=>{ this.switchVideBelonging(idx, vidx, element.belong, element.title)}}
//                                                 />
//                                         </ListItemIcon>
//                                     <ListItemText primary={
//                                         <React.Fragment>
//                                             <Typography component="p" color="textPrimary">
//                                                 {element.title}{element.id}
//                                             </Typography>
//                                         </React.Fragment>
//                                         }/>
//                                     </ListItem>
//                                 </div>
//                                 <div><Divider /></div>
//                             </div>
//                         }) : null
//                     }
//                 </List>
//         </List>
//     }
//     ) : null
// }
