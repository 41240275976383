import React, { Component } from 'react';

import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

class DeleteDialog extends Component {
    render(){
        return(
            <div>
                <Dialog
                    open={this.props.openModal}
                    onClose={this.props.handleClose}
                    scroll="paper"
                    fullWidth={true}
                    style={{minWidth: "70%"}}>
                        <DialogContent>
                        <DialogTitle>
                            確認
                        </DialogTitle>
                        <Typography variant="h6" style={{margin: "15px"}}>
                            「{this.props.title}」を削除してよろしいですか？
                        </Typography>
                        <div><Divider /></div>
                        <div style={{textAlign: "right", margin: "15px"}}>
                            <Button
                                style={{marginRight: "5px"}}
                                onClick={this.props.handleClose}>
                                キャンセル
                            </Button>
                            <Button
                                style={
                                    {
                                        color: '#FDFDFD',
                                        backgroundColor: '#C77373',
                                        border: '0px solid #C77373',
                                        borderRadius: 4,
                                    }
                                }
                                variant="outlined"
                                onClick={e => this.props.onDelete()}>
                                削除
                            </Button>
                        </div>
                    </DialogContent>
                </Dialog>
            </div>

        );
    }
}

export default withStyles()(DeleteDialog);