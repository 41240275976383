import React, {Component} from 'react';
import './App.css';
import {Route, Switch} from 'react-router-dom';

import Home from './components/Client/Home';
import Product from './components/Client/Products';
import Lecture from './components/Client/Lecture';
import Bookmarks from './components/Client/Bookmarks';
import Products from './components/Admin/Products';
import Categories from './components/Admin/Categories';
import EditCategory from './components/Admin/EditCategory';
import ManageInstructor from './components/Admin/ManageInstructor';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';

import client from './config/Vimeo';

client.request({
    method: 'GET',
    path: '/tutorial',
}, function(error, body, status_code, headers) {
    if (error) {
        console.log(error);
    }

    console.log(body);
});

class App extends Component {

    constructor(props) {
        super(props);
        if (process.env.NODE_ENV !== 'production') {
            document.title = `[${process.env.NODE_ENV}] ${document.title}`;
        }
    }

    render() {
        return (
            <div className="App">
                <MuiThemeProvider>
                    <Switch>
                        <Route exact path="/" component={Home}/>
                        <Route exact path="/bookmarks" component={Bookmarks}/>
                        <Route exact path="/bookmarks" component={Bookmarks}/>
                        {/* Product */}
                        <Route exact path="/products/:product_id"
                               component={Product}/>
                        <Route exact
                               path="/products/:product_id/learn/lecture/:video_id"
                               component={Lecture}/>
                        {/* Admin */}
                        <Route exact path="/admin/instructor"
                               component={ManageInstructor}/>
                        <Route exact path="/admin/products"
                               component={Products}/>
                        <Route exact path="/admin/categories"
                               component={Categories}/>
                        <Route exact path="/admin/categories/:category_id"
                               component={EditCategory}/>
                    </Switch>
                </MuiThemeProvider>
            </div>
        );
    }
}

export default App;


