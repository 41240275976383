let apiUrl
switch (process.env.NODE_ENV) {
    case 'development':
    case 'dev':
        apiUrl = {
            // LOGIN: `https://myaccount-stg.geniam.com/login`,
            // SIGNUP: `https://myaccount-stg.geniam.com/register`,
            // SWITCH_ACCOUNT: `https://myaccount-stg.geniam.com/login/select-account`,
            // MY_ACCOUNT: `https://myaccount-stg.geniam.com/account`,
            LOGIN: `https://myaccount.geniam.com/login`,
            SIGNUP: `https://myaccount.geniam.com/register`,
            SWITCH_ACCOUNT: `https://myaccount.geniam.com/login/select-account`,
            MY_ACCOUNT: `https://myaccount.geniam.com/account`,
        }
        break;
    default:
        apiUrl = {
            LOGIN: `https://myaccount.geniam.com/login`,
            SIGNUP: `https://myaccount.geniam.com/register`,
            SWITCH_ACCOUNT: `https://myaccount.geniam.com/login/select-account`,
            MY_ACCOUNT: `https://myaccount.geniam.com/account`,
        }
}
export default apiUrl

export let API_PREFIX;
switch (process.env.NODE_ENV) {
    case 'development':
    case 'dev':
        API_PREFIX = 'http://localhost:8000';
        break;
    default:
        API_PREFIX = 'https://strings.api.geniam.com';
}

//Product
export const GET_ALL_PRODUCTS =
    () => `${API_PREFIX}/products`
export const GET_PRODUCT =
    (id) => `${API_PREFIX}/products/${id}`


//Admin
// Category
export const GET_ALL_CATEGORIES = `${API_PREFIX}/admin/categories`
export const GET_CATEGORY =
    (id) => `${API_PREFIX}/admin/categories/${id}`
export const POST_CATEGORY =
    () => `${API_PREFIX}/admin/categories`;
export const PUT_CATEGORY =
    (id) => `${API_PREFIX}/admin/categories/${id}`;
export const DELETE_CATEGORY =
    (id) => `${API_PREFIX}/admin/categories/${id}`;

export const PUT_CATEGORY_CATEGORY_RELATION =
    (pid) => `${API_PREFIX}/admin/categories/parent/${pid}`;
export const DELETE_RELATION =
    (cid, pid) => `${API_PREFIX}/admin/categories/${cid}/parent/${pid}`;
export const PUT_CATEGORY_VIDEO_RELATION =
    (pid) => `${API_PREFIX}/admin/categories/videos/parent/${pid}`;
export const DELETE_CATEGORY_VIDEO_RELATION =
    (
        video_id,
        pid) => `${API_PREFIX}/admin/categories/${video_id}/parent/${pid}`;


// Product
export const GET_ALL_PRODUCTS_ADMIN = `${API_PREFIX}/admin/products`;
export const POST_PRODUCT =
    () => `${API_PREFIX}/admin/products`;
export const PUT_PRODUCT =
    (id) => `${API_PREFIX}/admin/products/${id}`;


// Bookmark関連
export const GET_BOOKMARKS =
    () => `${API_PREFIX}/bookmarks`

export const POST_BOOKMARK =
    (categoryVideo_id) => `${API_PREFIX}/bookmarks/${categoryVideo_id}`

export const DELETE_BOOKMARK =
    (categoryVideo_id) => `${API_PREFIX}/bookmarks/${categoryVideo_id}`


// Instructor関連
export const GET_INSTRUCTOR =
    (id) => `${API_PREFIX}/admin/instructor/${id}`
export const PUT_INSTRUCTOR =
    (id) => `${API_PREFIX}/admin/instructor/${id}`
export const ADD_INSTRUCTOR =
    () => `${API_PREFIX}/admin/instructor`
export const DELETE_INSTRUCTOR =
    (id) => `${API_PREFIX}/admin/instructor/${id}`
export const INSERT_USER_INFO =
    () => `${API_PREFIX}/user/insert`


// Batch処理
export const SYNC_VIDEO =
    () => `${API_PREFIX}/batch/video`
