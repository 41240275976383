import React, { Component } from 'react';
import axios from "axios";
import { connect } from "react-redux"
import { compose } from "redux";

import MenuAppBar from './MenuAppBar/MenuAppBar';
import { onAddBookmark, onDeleteBookmark } from "../../actions/bookmarks";
import { GET_PRODUCT } from "../../config/apiUrl";

import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import PlayCircleOutline from '@material-ui/icons/PlayCircleOutline';
import Bookmark from '@material-ui/icons/Bookmark';
import BookmarkBorder from '@material-ui/icons/BookmarkBorder';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const mapStateToProps = state => {
    return {
        auth: state.auth.initialState
    }
};

const styles = {
    root: {
        maxWidth: "1440px",
        heigth: "600px",
        backgroundColor: '#D9D8D8',
        margin: '10px auto',
    },
    lecturetitles: {
        display: 'flex',
    },
    list: {
        backgroundColor: 'white',
        height: 600,
        width: '100%'
    },
    details: {
        display: 'flex',
    },
    PlayCircle: {
        margin: 'auto',
    },
};


class Lecture extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            isLoading: true,
            error: [],
        }
    }

    componentDidMount() {
        // const { auth } = this.props.auth;
        const product_id = this.props.match.params.product_id
        console.log(product_id)
        axios.get(GET_PRODUCT(product_id)
        ).then(res => {
            this.setState({
                isLoading: false,
                data: res.data
            })
        }).catch(e => {
            this.setState({ isLoading: false })
            console.log(e)
        })
    };

    async switchBookmark(cidx, vidx, bookmarked, categoryVideo_id) {
        const t = this.state.data
        t[3][cidx]["videosList"][vidx]["video"]["bookmarked"] = !bookmarked
        try {
            this.setState({
                data: t,
                isLoading: true
            })
            if (bookmarked) {
                await onDeleteBookmark(categoryVideo_id)
            } else {
                await onAddBookmark(categoryVideo_id)
            }
        } catch (e) {
            console.log(e)
        } finally {
            this.setState({ isLoading: false })
        }
    }

    render() {
        const { classes } = this.props
        const auth = this.props.auth.auth
        const isLoading = this.state.isLoading
        const product_id = this.props.match.params.product_id
        const video_id = this.props.match.params.video_id
        //productのデータが入っている
        const data = this.state.data
        console.log(data)
        const product = data[0] ? data[0] : {}
        console.log(product)
        const categories = data[0] ? data[3] : []
        const categoriesList = categories.map((n, cidx) => {
                const categoryInfo = n.categoryInfo
                const videosList = n.videosList
                return <div key={cidx}>
                    <ExpansionPanel>
                        <ExpansionPanelSummary
                            style={{ backgroundColor: 'textSecondary' }}
                            expandIcon={<ExpandMoreIcon/>}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography style={{ textAlign: 'left' }} variant="body1" color="textPrimary">
                                {categoryInfo.title}
                            </Typography>
                        </ExpansionPanelSummary>
                        <div><Divider/></div>
                        {/* Expansionの中身を記述 */}
                        <ExpansionPanelDetails>
                            <List>
                                {
                                    videosList.map((n, vidx) => {
                                        const m = n.video
                                        const categoryVideo_id = n.categoryVideo.id
                                        return <div key={vidx}>
                                            <div className={classes.lecturetitles}>
                                                <ListItem alignItems="flex-start">
                                                    <ListItemText
                                                        primary={
                                                            <React.Fragment>
                                                                <Typography color="textPrimary">
                                                                    {m.title}
                                                                </Typography>
                                                            </React.Fragment>
                                                        }/>
                                                </ListItem>
                                                <ListItemIcon button>
                                                    <IconButton className={classes.PlayCircle}
                                                                href={`/products/${product_id}/learn/lecture/${m.id}`}>
                                                        <PlayCircleOutline/>
                                                    </IconButton>
                                                </ListItemIcon>
                                                <ListItemIcon button>
                                                    <div className={classes.details}>
                                                        {
                                                            m.bookmarked ?
                                                                <IconButton className={classes.PlayCircle}
                                                                            onClick={() => {
                                                                                this.switchBookmark(cidx, vidx, m.bookmarked, categoryVideo_id)
                                                                            }}
                                                                            disabled={isLoading}>
                                                                    <Bookmark className={classes.PlayCircleIcon}/>
                                                                </IconButton>
                                                                :
                                                                <IconButton className={classes.PlayCircle}
                                                                            onClick={() => {
                                                                                this.switchBookmark(cidx, vidx, m.bookmarked, categoryVideo_id)
                                                                            }}
                                                                            disabled={isLoading}>
                                                                    <BookmarkBorder className={classes.PlayCircleIcon}/>
                                                                </IconButton>
                                                        }
                                                    </div>
                                                </ListItemIcon>
                                            </div>
                                            <div><Divider/></div>
                                        </div>
                                    })
                                }
                            </List>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                </div>
            }
        )
        return (
            <div className={classes.root}>
                <MenuAppBar/>
                <Grid container
                      direction="row"
                      justify="center"
                      alignItems="center"
                >
                    <Grid item sm={12} lg={8}>
                        <iframe src={`https://player.vimeo.com/video/${video_id}`} width={'100%'} height='600px'
                                frameBorder="0"
                                // webkitallowfullscreen={true} mozallowfullscreen={true}
                                allowFullScreen={true}></iframe>
                    </Grid>
                    <Grid item sm={12} lg={4} style={{ overflowY: 'scroll' }} className={classes.list}>
                        <Typography style={{ textAlign: 'left', margin: '15px' }} variant="h6" color="textPrimary">
                            {product.title}
                        </Typography>
                        {categoriesList}
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default compose(withStyles(styles), connect(mapStateToProps))(Lecture);
