import React, { Component } from 'react';

import { withStyles } from "@material-ui/core/styles";
import { withRouter } from 'react-router';
import { connect } from 'react-redux'
import axios from 'axios';

import Appbar from './appbar'
import CategoryInfo from './CategoryInfo'
import ManageParents from './ManageParents';
import ManageChildren from './ManageChildren'
import ManageVideos from './ManageVideos'
import './AdminStyle.css';

import Paper from "@material-ui/core/Paper";

import { GET_CATEGORY } from "../../config/apiUrl";


const styles = theme => ({
    root: {
        width: '60%',
        margin: "auto",
        overflowX: 'auto',
    },
    section: {
        marginBottom: '30px'
    },
    formControl: {
        display: 'flex',
        paddingTop: '5px',
        paddingBottom: '5px',
        alignItems: 'center',
    },
    cancelButton: {
        color: '#2F4F4F',
        backgroundColor: '#FDFDFD',
        border: '1px solid #2F4F4F',
        borderRadius: 4,
    },
    button: {
        color: '#FDFDFD',
        backgroundColor: '#8BAFED',
        borderRadius: 4,
    },
    header: {
        paddingLeft: '20px',
        paddingBottom: '5px',
        paddingTop:'5px',
        marginBottom: '10px',
        textAlign: 'left',
        width: '100%',
        backgroundColor: '#707070', 
        borderRadius: 4,
        color: '#F8F8F8'
    }
});
  
class EditCategory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {},
            isLoading: true,
            error: [],
        }
    }

    componentDidMount(){
        const pid = this.props.match.params.category_id
        this.reload(pid);
    }

    componentDidUpdate(prevProps) {
        const pid = this.props.match.params.category_id
        const prevCategoryId = prevProps.match.params.category_id;
        if (pid !== prevCategoryId) {
          this.reload(pid);
        }
      }

    reload = async (id) => {
        this.setState({isLoading:true, error:[]})
        await axios.get(GET_CATEGORY(id))
        .then(res => {
            this.setState({ 
                data: res.data,
                isLoading: false
            })
        })
        .catch((error) => console.log(error))        
    }

    render() {
        // const { params } = this.params.match;
        const { classes } = this.props;
        const pid = this.props.match.params.category_id;
        console.log(this.state.data)
        const category = this.state.data[0] ? this.state.data[0]['c'] : {};
        const product = this.state.data[1] ? this.state.data[0]['p'] : {};
        const categories = this.state.data[1] ? this.state.data[1] : {};
        const parents = this.state.data[2] ? this.state.data[2] : {};
        const children = this.state.data[3] ? this.state.data[3] : {};
        const videos = this.state.data[4] ? this.state.data[4] : {};
        const videosList = this.state.data[5] ? this.state.data[5] : {};
        return (
            <div>
                <Appbar />
                    {
                        this.state.isLoading ? 
                        <img src="https://s3-ap-northeast-1.amazonaws.com/togo.static/img/loading.gif" /> :
                    <Paper className={classes.root}>
                        <CategoryInfo 
                            category={category}
                            product={product}
                            reload={(id) => { this.reload(id)}}
                        />
                        <ManageParents
                            pid={pid}
                            categoryType={"親カテゴリー"}
                            parents={parents}
                            categories={categories}
                            reload={(id) => { this.reload(id)} }
                        /> 
                        <ManageChildren
                            pid={pid}
                            categoryType={"子カテゴリー"}
                            children={children}
                            categories={categories}
                            reload={(id) => { this.reload(id)} }
                        />
                        <ManageVideos 
                            style={{width:'100%'}} 
                            videos={videos} 
                            videosList={videosList} 
                        />
                    </Paper>
                    }
            </div>
        );
    }
}

export default withRouter(connect()(withStyles(styles)(EditCategory)))