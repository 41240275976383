import React, { Component } from 'react';

import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';

class ConfirmationDialog extends Component {
    render(){
        return(
            <div>
                <Dialog
                    open={this.props.openConfirmationModal}
                    onClose={this.props.handleClose}
                    scroll="paper"
                    fullWidth={true}
                    style={{minWidth: "70%"}}>
                    <DialogContent style={{textAlign: "center"}}>
                        {
                            this.props.isDoing ? 
                            <Typography variant="h6">
                                {this.props.isDoingComment}
                            </Typography>
                            : 
                            <div>
                                <Typography variant="h6">
                                    {this.props.isDoneComment}
                                </Typography>
                                <Button
                                    variant="outlined"
                                    onClick={this.props.handleClose}
                                    style={
                                        {
                                            margin: "15px",
                                            width: '50%',
                                            color: '#2F4F4F',
                                            border: '1px solid #2F4F4F',
                                            backgroundColor: '#FDFDFD',
                                            borderRadius: 4,
                                        }
                                    }
                                    >
                                    閉じる
                                </Button>
                            </div>
                        }
                    </DialogContent>
                </Dialog>
            </div>

        );
    }
}

export default withStyles()(ConfirmationDialog);