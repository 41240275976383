import { SET_COURSES, FETCH_COURSES, SET_COURSE_DATA } from '../constants/actionTypes';

const defaultState = {
    coursesData: [],
    courseName: [],
    isLoading: false,
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case FETCH_COURSES:
            return {
                ...state,
                isLoading: true
            }

        case SET_COURSES:
            return {
                ...state,
                coursesData: action.coursesData,
                isLoading: false
            }

        case SET_COURSE_DATA:
            return {
                ...state,
                courseName: action.courseName,
                isLoading: false
            }
        default:
            return state
    }
}
