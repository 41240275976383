import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { history, store } from './store';
import { Router } from 'react-router-dom'
import Auth from './auth';
import App from './App';
import configAxios from './config/axios'
import * as serviceWorker from './serviceWorker';
import './index.css';


configAxios()

Auth.Core.setToken("token", { "store": store }).auth(function () {
    ReactDOM.render((
        <Provider store={store}>
            <Router history={history}>
                <App/>
            </Router>
        </Provider>
    ), document.getElementById('root'));
});

serviceWorker.register()

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

