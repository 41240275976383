import {
    SET_VIDEOS,
    FETCH_VIDEOS,
    FETCH_VIDEOS_DONE,
    SET_LECTURE_TITLES,
    SWITCH_BOOKMARK,
    GET_ALL
} from '../constants/actionTypes';

const defaultState = {
    allData: [],
    videoData: [],
    isLoading: false,
    lectureTitles: [],
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case FETCH_VIDEOS:
            return {
                ...state,
                isLoading: true
            }
        case FETCH_VIDEOS_DONE:
            return {
                ...state,
                isLoading: false
            }
        case SET_VIDEOS:
            return {
                ...state,
                videoData: action.videoData,
                isLoading: false
            }
        case SET_LECTURE_TITLES:
            return {
                ...state,
                lectureTitles: action.lectureTitles,
                isLoading: false
            }
        case GET_ALL:
            return {
                ...state,
                allData: action.allData,
                isLoading: false
            }
        case SWITCH_BOOKMARK:
            return {
                ...state,
                videoData: action.videoData,
                isLoading: false
            }
        default:
            return state
    }
}
