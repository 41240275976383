import React from 'react';
import {Link} from 'react-router-dom';
import classNames from 'classnames';

import {withStyles} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';
import ListItem from '@material-ui/core/ListItem/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText/ListItemText';
import Person from '@material-ui/icons/Person';
import ViewList from '@material-ui/icons/ViewList';
import Category from '@material-ui/icons/Category';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button/Button';
import Slide from '@material-ui/core/Slide/Slide';

const drawerWidth = 240;

const styles = theme => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
        backgroundColor: '#3A3A3A',
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginLeft: 12,
        marginRight: 36,
    },
    menuButton2: {
        padingTop: 12,
        marginLeft: 24,
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        textAlign: 'left',
        color: 'white'
    },
    drawerPaper: {
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,

});

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

class Appbar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            logout: false
        };
        this.handleDrawerOpen = this.handleDrawerOpen.bind(this);
        this.handleDrawerClose = this.handleDrawerClose.bind(this);
        this.handleDrawerOpen = this.handleDrawerOpen.bind(this)
    }


    handleDrawerOpen = () => {
        this.setState({ open: true });
    };

    handleDrawerClose = () => {
        this.setState({ open: false });
    };

    render() {
        const { classes } = this.props;
        return (
            <React.Fragment>
                <CssBaseline/>
                <div className={classes.root}>
                    <AppBar
                        position="fixed"
                        className={classNames(classes.appBar, this.state.open && classes.appBarShift)}
                    >
                        <Toolbar disableGutters={!this.state.open} className={classes.toolbar}>
                            <IconButton
                                color="inherit"
                                aria-label="Open drawer"
                                onClick={this.handleDrawerOpen}
                                className={classNames(
                                    classes.menuButton,
                                    this.state.open && classes.menuButtonHidden,
                                )}
                            >
                                <MenuIcon/>
                            </IconButton>
                            <Link to='/admin/products' style={{ textDecoration: 'none' }}>
                                <Typography
                                    variant="title"
                                    color="inherit"
                                    noWrap
                                    className={classes.title}
                                    style={{ marginRight: "100px" }}
                                >
                                    Strings Admin
                                </Typography>
                            </Link>
                            <Button
                                target="_blank"
                                color="inherit"
                                href='https://togo-manage-stg.geniam.com/'
                                style={{ float: 'right' }}
                            >
                                <Typography color="inherit">平野さんの参考（togo）</Typography>
                            </Button>
                            <Link to="/" style={{textDecoration: 'none'}}>
                                <Typography
                                    style={{float: 'right'}}
                                    color="inherit"
                                    noWrap
                                    className={classes.title}
                                >
                                    クライアント側に戻る（開発用）
                                </Typography>
                            </Link>
                            {/*<Button*/}
                            {/*    target="_blank"*/}
                            {/*    color="inherit"*/}
                            {/*    href='https://s3.console.aws.amazon.com/s3/buckets/strings.geniam.com/?region=ap-northeast-1'*/}
                            {/*    style={{ float: 'right' }}*/}
                            {/*>*/}
                            {/*    <Typography color="inherit">S3</Typography>*/}
                            {/*</Button>*/}
                        </Toolbar>

                    </AppBar>
                    <Drawer
                        classes={{
                            paper: classNames(classes.drawerPaper, !this.state.open && classes.drawerPaperClose),
                        }}
                        variant="permanent"
                        open={this.state.open}
                        onClose={this.handleDrawerOpen}>

                        <div
                            tabIndex={0}
                            role="button"
                        >
                            <div>
                                <div
                                    onClick={this.handleDrawerClose}
                                >
                                    <ListItem button className={classes.toolbarIcon}>
                                        <ListItemIcon>
                                            <ChevronLeftIcon/>
                                        </ListItemIcon>
                                    </ListItem>
                                </div>

                                <Link to='/admin/instructor'><ListItem button>
                                    <ListItemIcon>
                                        <Person/>
                                    </ListItemIcon>
                                    <ListItemText primary="講師情報"/>
                                </ListItem>
                                </Link>
                                <Link to='/admin/products'>
                                    <ListItem button>
                                        <ListItemIcon>
                                            <ViewList/>
                                        </ListItemIcon>
                                        <ListItemText primary="プログラム一覧"/>
                                    </ListItem>
                                </Link>
                                <Link to='/admin/categories'>
                                    <ListItem button>
                                        <ListItemIcon>
                                            <Category/>
                                        </ListItemIcon>
                                        <ListItemText primary="カテゴリー一覧"/>
                                    </ListItem>
                                </Link>
                            </div>
                        </div>
                    </Drawer>
                    <div className={classes.appBarSpacer}>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}


export default withStyles(styles)(Appbar);
