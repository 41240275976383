import React from 'react';
import {Typography} from '@material-ui/core';

class Footer extends React.Component {
    render() {
        return (
            <div>
                <footer style={{
                    background: '#333'
                }}>
                    <Typography style={{
                        padding: '20px',
                        color: 'aliceblue',
                    }}>
                        © 2018 Andom.inc All Rights Reserved.
                    </Typography>
                </footer>
            </div>
        )
    }

}

export default (Footer);
