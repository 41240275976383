import React, { Component } from 'react';

import { withRouter } from 'react-router';
import { connect } from 'react-redux'
import axios from 'axios';

import Appbar from './appbar'
import ConfirmationDialog from './ConfirmationDialog'

import {
    Paper, Typography, withStyles,
    Avatar, Button, InputBase,
    FormControl
} from '@material-ui/core';

import './AdminStyle.css';

import { GET_INSTRUCTOR, PUT_INSTRUCTOR } from "../../config/apiUrl";


const styles = theme => ({
    root: {
        width: '80%',
        margin: "auto",
        overflowX: 'auto',
    },
    section: {
        marginBottom: '30px'
    },
    formControl: {
        display: 'flex',
        paddingTop: '5px',
        paddingBottom: '5px',
        alignItems: 'center',
    },
    input: {
        width: '100%',
        borderRadius: 4,
        border: '1px solid #ced4da',
        backgroundColor: '#FDFDFD',
        padding: '3px 10px',
    },
    inputMulti: {
        width: '100%',
        borderRadius: 4,
        border: '1px solid #ced4da',
        backgroundColor: '#FDFDFD',
        padding: '10px',
    },
    form: {
        padding: 30,
    },
    button: {
        color: '#FDFDFD',
        backgroundColor: '#8BAFED',
        borderRadius: 4,
    },
    table: {
        tableLayout: 'auto'
    },
    err: {
        color: 'red'
    },
    header: {
        width: '80%',
        paddingLeft: '20px',
        paddingBottom: '5px',
        paddingTop: '5px',
        margin: 'auto',
        marginTop: '5px',
        textAlign: 'left',
        backgroundColor: '#707070',
        borderRadius: 4,
        color: '#F8F8F8',
        overflowX: 'auto',
    },
});

class ManageInstructor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            instructor: [],
            isLoading: false,
            error: [],
            openConfirmationModal: false,
            isDoing: false
        }
    }

    componentDidMount() {
        const id = 5
        axios.get(GET_INSTRUCTOR(id)
        ).then(res => {
            console.log(res.data)
            this.setState({ instructor: res.data[0] })
        })
    }

    changeParam(param, value) {
        const t = this.state.instructor
        t[param] = value;
        this.setState({ instructor: t })
    }

    handleClose = () => {
        this.setState({ 
            openConfirmationModal: false
        });
        };

    onCommit(id){
        const instructor = this.state.instructor
        this.setState({
            error:[],
            openConfirmationModal: true,
            isDoing: true
        })
        axios.put(PUT_INSTRUCTOR(id),{instructor})  
        .then(res => {
            this.setState({ isDoing: false })
            this.props.history.push("/admin/instructor")
        })
        .catch(error => {
            this.setState({ loading: false, error: ["更新に失敗しました"] })
        })

    }

    render() {
        const { classes } = this.props;
        const instructor = this.state.instructor;
        console.log(instructor)
        const id = "5"
        return (
            <div>
                <Appbar />
                {
                    this.state.isLoading ?
                        <img src="https://s3-ap-northeast-1.amazonaws.com/togo.static/img/loading.gif" /> :
                    <div>
                        <Typography variant="h6" className={classes.header}>
                            インストラクター
                        </Typography>
                        <Paper className={classes.root}>
                        <form className={classes.form}>
                            <div className={classes.section}>
                                <div className={classes.formControl}>
                                    <Typography variant='h6' style={{ width: '30%', textAlign: 'left' }}>
                                        姓 
                                    </Typography>
                                    <FormControl required fullWidth>
                                        <InputBase
                                            className={classes.input}
                                            variant='outlined'
                                            value={instructor.last_name}
                                            onChange={(e) => { this.changeParam("last_name", e.currentTarget.value) }}
                                        />
                                    </FormControl>
                                </div>
                                <div className={classes.formControl}>
                                    <Typography variant='h6' style={{ width: '30%', textAlign: 'left' }}>
                                        名
                                    </Typography>
                                    <FormControl required fullWidth>
                                        <InputBase
                                            className={classes.input}
                                            variant='outlined'
                                            value={instructor.first_name}
                                            onChange={(e) => { this.changeParam("first_name", e.currentTarget.value) }} />
                                    </FormControl>
                                </div>
                                <div className={classes.formControl}>
                                    <Typography variant='h6' style={{ width: '30%', textAlign: 'left' }}>
                                        経歴
                                    </Typography>
                                    <FormControl required fullWidth>
                                        <InputBase
                                            className={classes.inputMulti}
                                            variant='outlined'
                                            multiline
                                            rows='6'
                                            value={instructor.career}
                                            onChange={(e) => { this.changeParam("career", e.currentTarget.value) }} />
                                    </FormControl>
                                </div>
                                <div className={classes.formControl}>
                                    <Typography variant='h6' style={{ width: '30%', textAlign: 'left' }}>
                                        プロフィール画像
                                    </Typography>
                                    <FormControl required fullWidth>
                                        <InputBase
                                            className={classes.input}
                                            variant='outlined'
                                            value={instructor.thumbnail}
                                            onChange={(e) => { this.changeParam("thumbnail", e.currentTarget.value) }} />
                                    </FormControl>
                                </div>
                                <div style={{textAlign: "right"}}>
                                    <Avatar alt="Remy Sharp" src={instructor.thumbnail} 
                                        style={{width: "200px", height: "200px"}}/>
                                </div>
                            <Button
                                className={classes.button}
                                style={{float: 'right', width: '40%'}}
                                onClick={e => this.onCommit(id)}>
                                    更新
                            </Button>
                            <ConfirmationDialog
                                isDoing={this.state.isDoing}
                                openConfirmationModal={this.state.openConfirmationModal}
                                isDoingComment={"更新しています"}
                                isDoneComment={"更新が完了しました"}
                                handleClose={() => { this.handleClose() }}
                            />
                            </div>
                        </form>
                    </Paper>
                </div>
                }
            </div>
        );
    }
}

export default withRouter(connect()(withStyles(styles)(ManageInstructor)))