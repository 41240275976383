import React from 'react'
import { Button } from "@material-ui/core";
import './LoginRegisterButtons.css';
import apiUrl from "../../../config/apiUrl";


class LoginRegisterButtons extends React.Component {
    signIn = () => {
        let url = apiUrl.LOGIN + '?redirect_url=' + window.location.href;
        window.location.assign(url);
    }
    onSignUp = () => {
        let url = apiUrl.SIGNUP + '?redirect_url=' + window.location.href;
        window.location.assign(url);
    }
    render() {
        return (
            <div className="flex-left-right">
                <div className="mr10">
                    <Button
                        className='login-signup-button'
                        style={{ backgroundColor: 'white'}}
                        onClick={this.signIn}
                    >ログイン</Button>
                </div>

                <div className="mr10">
                    <Button
                        className="login-signup-button"
                        style={{ backgroundColor: '#eac545'}}
                        onClick={this.onSignUp}
                    >
                        新規登録
                    </Button>
                </div>
            </div>
        );
    }
}

export default LoginRegisterButtons

