export function getParamToken(sParam) {
    var sPageURL = window.location.search.substring(1);
    var sURLVariables = sPageURL.split('&');
    for (var i = 0; i < sURLVariables.length; i++) {
        var sParameterName = sURLVariables[i].split('=');
        if (sParameterName[0] === sParam) {
            return sParameterName[1];
        }
    }
}
export function createQueryParams(params = {}) {
    return Object.keys(params)
        .map(k => {
            if (params[k]) {
                return `${k}=${params[k]}`
            }
            return '';
        })
        .join('&');
}
export function removeURLParameter(parameter, url) {
    var fragment = url.split('#');
    var urlparts= fragment[0].split('?');

    if (urlparts.length>=2)
    {
        var urlBase=urlparts.shift(); //get first part, and remove from array
        var queryString=urlparts.join("?"); //join it back up

        var prefix = encodeURIComponent(parameter)+'=';
        var pars = queryString.split(/[&;]/g);
        for (var i= pars.length; i-->0;) {               //reverse iteration as may be destructive
            if (pars[i].lastIndexOf(prefix, 0)!==-1) {   //idiom for string.startsWith
                pars.splice(i, 1);
            }
        }
        url = urlBase + (pars.length > 0 ? '?' + pars.join('&') : '');
        if (fragment[1]) {
            url += "#" + fragment[1];
        }
    }
    return url;
}
export function setOGP(url, meta) {
  meta.content = url;
}
export function getOGPImg(src) {
    return 'https://s3-ap-northeast-1.amazonaws.com/geniam-image/inner-tour/ogp/'+src;
}
export function numberFormat(value, fix = 0, space = ".") {
    let num = parseInt(value);
    if (num) {
        let p = num.toFixed(fix).split(".");
        return p[0].split("").reduceRight(function (acc, num, i, orig) {
            if ("-" === num && 0 === i) {
                return num + acc;
            }
            let pos = orig.length - i - 1;
            return num + (pos && !(pos % 3) ? space : "") + acc;
        }, "") + (p[1] ? "." + p[1] : "");
    }
    return 0;
}