import React, {Component} from 'react';
import PropTypes from 'prop-types';

import {
    Avatar,
    Button,
    Card,
    CardActions,
    CardContent,
    Grid,
    Popover,
    Typography,
    withStyles,
} from '@material-ui/core';
import {Home} from '@material-ui/icons';
import './AppListPopup.css';
import {createQueryParams} from '../../../helper/utility';

const styles = theme => ({
    card: {
        maxWidth: 700,
        // display: 'flex',
        textAlign: 'center',
    },
    info_card: {
        display: 'flex',
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        flex: '1 0 auto',
    },
    cover: {
        minWidth: 80,
        height: 80
    },
    serviceIcons: {
        width: 60,
        height: 60,
        marginTop: 10,
        marginBottom: 10
    }
});

class CardWithImageOnRight extends React.Component {
    render() {
        const { classes, title, content, image, link } = this.props;
        return (
            <Card className={classes.info_card}>
                <a href={link ? link : "#"} style={{ color: "#111", textDecoration: "none" }}>
                    {/* contents */}
                    <div style={{ textAlign: 'left' }}>
                        <CardContent>
                            <img src={image} style={{ width: "100px", float: "right", margin: "0 0 0 0" }} alt={"right_img"}/>
                            <div className={"larger"}>{title}</div>
                            <div style={{ fontSize: "small" }}>
                                {content}
                            </div>
                        </CardContent>
                    </div>
                </a>
            </Card>
        );
    }
}

class AppListPopup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            anchorEl: props.anchorEl,
            applist: [
                {
                    name: "enecolor",
                    img: "/static/images/enecolor.png",
                    url: "https://enecolor.geniam.com"
                },
                {
                    name: "insight-mind",
                    img: "/static/images/insight-mind.png",
                    url: "https://insightmind.geniam.com"
                }
            ]
        };
    }

    static getDerivedStateFromProps(props, state) {
        if (props.anchorEl !== state.anchorEl) {
            return {
                anchorEl: props.anchorEl,
            };
        }
        return null;
    }

    render() {

        const { anchorEl } = this.state;
        const isOpen = Boolean(anchorEl);
        if (!isOpen) return null;
        const { onClose, classes } = this.props;
        const { applist } = this.state;

        return (
            <Popover
                id={"AppListPopup"}
                open={isOpen}
                anchorEl={anchorEl}
                onClose={onClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}>

                <Card className={classes.card}>
                    <div>
                        <CardContent>
                            <Typography gutterBottom variant="headline" component="h2">
                                Geniamサービス
                            </Typography>

                            <Grid container>
                                {
                                    applist && applist.map((app) => {
                                        return (
                                            <Grid item xs={3} key={app.name}
                                                  style={{ cursor: "pointer" }}
                                                  className={"AppListPopup-ServiceList"}
                                                  onClick={() => {
                                                      this.onClickServiceIcon(app.name)
                                                  }}
                                            >
                                                <Avatar
                                                    className={classes.serviceIcons}
                                                    src={app.img}
                                                />
                                            </Grid>
                                        )
                                    })
                                }
                            </Grid>
                        </CardContent>
                    </div>
                    <Grid container>
                        <Grid item xs={6}
                              className={classes.info_card}
                              style={{ borderTop: 'solid 1px' }}>
                            <CardWithImageOnRight classes={classes}
                                                  style={{ borderTop: 10 }}
                                                  title={"オススメNews"}
                                                  content={`Enecolor の解析結果を基に、AI がパフォーマンス向上のヒントを毎日メールで見ることができます。`}
                                                  image={"/static/images/togo_logo.png"}
                                                  link="https://enecolor.geniam.com/campaign1"
                            />

                        </Grid>
                        <Grid item xs={6}
                              className={classes.info_card}
                              style={{ borderTop: 'solid 1px', borderLeft: 'solid 1px' }}>
                            <CardWithImageOnRight classes={classes}
                                                  title={"New Contents"}
                                                  content={`Enecolor 診断の個別解析を基に、数兆通りのパターンから、あなたのためだけの自己発揮への５０のポイントが、１３０Pの冊子になってお手元に届きます。`}
                                                  image={"/static/images/en_book.png"}
                                                  link="https://enecolor.geniam.com/campaign1#book"/>
                        </Grid>
                    </Grid>
                    <CardActions className="AppListPopup-CardActions">
                        <Button size="small" color="default"
                                onClick={() => {
                                    window.open('https://geniam.com', '_blank')
                                }}>
                            <Home/>
                            Geniamとは？
                        </Button>
                        {/*<div style={{ flex: 1 }}/>*/}
                        <Button size="small" color="default"
                                onClick={() => {
                                    window.open('https://geniam.com', '_blank');
                                }}>
                            運営会社Andom
                        </Button>
                    </CardActions>
                </Card>
            </Popover>
        );
    }

    onClickServiceIcon(name) {
        this.state.applist.forEach((app) => {
            if (app.name === name) {
                const token = localStorage.getItem('token');
                const refreshToken = localStorage.getItem('refreshToken');
                let searchMark = "?";
                if (app.url && (app.url.indexOf("?") > -1)) {
                    searchMark = "&"
                }
                window.open(app.url + searchMark + createQueryParams({ token, refreshToken }), '_blank')
            }
        })
    }
}

AppListPopup.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AppListPopup)
