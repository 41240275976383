import React, { Component } from 'react';
import MenuAppBar from './MenuAppBar/MenuAppBar';
import ProductsBox from '../Client/ProductsBox';
import Footer from './Footer/Footer';

import { connect } from "react-redux"
import { compose } from "redux";
import { withStyles } from '@material-ui/core/styles';
import '../../style.css';

const mapStateToProps = state => {
    return {
        coursesData: state.coursesData
    }
};

const styles = {
};

class Home extends Component {
    render() {
        return (
            <div>
                <MenuAppBar />
                <ProductsBox />
                <Footer/>
            </div>
        );
    }
}


export default compose(
    withStyles(styles),
    connect(mapStateToProps),
)(Home);