import { FETCH_PRODUCT, FETCH_PRODUCT_DONE, GET_PRODUCT_DATA, UPDATE_PRODUCT_DATA } from '../constants/actionTypes';

const defaultState = {
    product_data: [],
    isLoading: false,
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case FETCH_PRODUCT:
            return {
                ...state,
                isLoading: true
            }
        case FETCH_PRODUCT_DONE:
            return {
                ...state,
                isLoading: false
            }
        case GET_PRODUCT_DATA:
            return {
                ...state,
                product_data: action.data,
                isLoading: false
            }
        case UPDATE_PRODUCT_DATA:
            return {
                ...state,
                product_data: action.product_data
            }
        default:
            return state
    }
}

