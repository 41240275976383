import React, { Component } from 'react';
import {
    Grid, Typography, withStyles,
    Avatar
} from '@material-ui/core';

const styles = {
    wrapper: {
      width: '100%',
      margin: '10px 0'
    },
    root: {
        display: 'flex',
        margin: 40,

    },
    text: {
        textAlign: "left",
        marginTop: 0,
        marginBottom: 10,
        marginLeft: 20,
        marginRight: 20,
    },
    explanation: {
        textAlign: "left",
        marginTop: 30,
        marginBottom: 30,
        marginLeft: 20,
        marginRight: 20,
    }
};

class InstructorIntro extends Component {
    render() {
        const { classes } = this.props;
        const instructor = this.props.instructor
        return (
            <div className={classes.wrapper}>
                <Grid container direction="column" style={{marginBottom: "40px"}}>
                    <Grid item>
                        <Typography gutterBottom variant="h5" style={{textAlign: "left"}}>
                            講師紹介
                        </Typography>
                    </Grid>
                    <Grid item container>
                        <Grid item sm={12} md={3}>
                        <Avatar src  ={instructor.thumbnail} 
                                style={{width: "200px", height: "200px"}}/>
                        </Grid>
                        <Grid container item sm={12} md={9} 
                            direction  = "column"
                            justify    = "flex-start"
                            alignItems = "flex-start"
                            style      = {{paddingLeft: "20px"}} >
                            <Grid item>
                                <Typography gutterBottom variant="h6">
                                    {instructor.last_name}{instructor.first_name}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography style={{textAlign: "left"}}>
                                    {instructor.career}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default withStyles(styles)(InstructorIntro);

/*
style={{backgroundColor: '#919191'}}
style={{backgroundColor: '#414141'}}
style={{backgroundColor: '#707070', paddingLeft: "10px"}}
*/

{/* <div className='full'>
<Typography gutterBottom variant="h5" component="h2" className={classes.text}>
    講師紹介
</Typography>
<div className='instructor'>
    <img src="../../Home/img1.png" className='instructor-pic' />
    <div>
        <Typography component="p" variant="h6" className={classes.text}>
        {instructor.last_name}{instructor.first_name}
        </Typography>
        <Typography component="p" className={classes.explanation}>
            {instructor.career}
        </Typography>
    </div>
</div>
</div>  */}