import React, {Component} from 'react';
import axios from "axios";
import {withRouter} from 'react-router';
import {Link} from 'react-router-dom'
import {connect} from "react-redux"

import {GET_PRODUCT} from "../../config/apiUrl";

import MenuAppBar from './MenuAppBar/MenuAppBar';
import CategoriesList from './CategoriesList';
import InstructorIntro from './InstructorIntro';
import Footer from './Footer/Footer';

import {onAddBookmark, onDeleteBookmark} from "../../actions/bookmarks";

import {Button, Grid, Typography, withStyles} from '@material-ui/core';


const mapStateToProps = state => {
    return {
        auth: state.auth.initialState
    }
};

const styles = {
    wrapper: {
        maxWidth: '1100px',
        margin: '10px auto',
        padding: "0 30px"
    },
    root: {
        marginTop: '24px',
        marginBottom: '24px',
        marginLeft: '51px',
        marginRight: '51px',
        maxWidth: '1338px',
    },
    homePic: {
        width: "100%"
    },
    coursetitle: {
        marginLeft: '12px',
        textAlign: 'left',
    },
    homesubtitle: {
        textAlign: "left",
        marginLeft: '51px',
        marginBottom: '12px',
    },
    coursewhat: {
        textAlign: "left",
    },
    explanationwhat: {
        textAlign: "left",
    },
    coursewhy: {
        textAlign: "left",
        marginLeft: '12px',
    },
    explanationwhy: {
        textAlign: "left",
        marginLeft: '12px',
    },
    button: {
        color: '#FDFDFD',
        backgroundColor: '#8BAFED',
        borderRadius: 4,
        padding: '0 60px'
    },
};

class Product extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            isLoading: true,
            error: [],
        }
    }

    componentDidMount(){
        // const { auth } = this.props.auth;
        const product_id = this.props.match.params.product_id
        console.log(product_id)
        axios.get(GET_PRODUCT(product_id)
        ).then(res =>{
            this.setState({
                isLoading: false,
                data: res.data
            })
        }).catch(e =>{
            this.setState({isLoading: false})
            console.log(e)
        })
    };

    async switchBookmark(cidx, vidx, bookmarked, categoryVideo_id){
        console.log(bookmarked)
        const t = this.state.data
        t[3][cidx]["videosList"][vidx]["video"]["bookmarked"] = !bookmarked
        console.log(t[3][cidx]["videosList"][vidx]["video"]["bookmarked"])
        console.log(bookmarked)
        try {
            this.setState({
                data: t,
                isLoading: true
            })
            if (bookmarked) {
                await onDeleteBookmark(categoryVideo_id)
            } else {
                await onAddBookmark(categoryVideo_id)
            }
        }catch(e) {
            console.log(e)
        }finally{
            this.setState({isLoading: false})
        }
    }

    render() {
        const { classes } = this.props
        const auth        = this.props.auth.auth
        const isLoading   = this.state.isLoading
        const product_id  = this.props.match.params.product_id
        //productのデータが入っている
        const data = this.state.data
        const product    = data[0] ? data[0] : {}
        const category   = data[0] ? data[1] : {}
        const instructor = data[0] ? data[2] : {}
        const categories = data[0] ? data[3] : {}
        console.log(categories)
        const a = category[0] ? category.description : ""
        const lines = a.split('\n').map((line, idx) => { return <div key={idx}><Typography component="p" className={classes.explanation}>{line}</Typography><br /></div> })
        return (
            <div>
                {
                    isLoading ? <img src="https://s3-ap-northeast-1.amazonaws.com/togo.static/img/loading.gif" />
                        : <div>
                            <div className={classes.wrapper}>
                                <MenuAppBar />
                                <div>
                                    <Grid container style={{ minWidth: "800px", marginBottom: "40px" }}>
                                        <Grid item sm={12} md={8}>
                                            <img src={category.thumbnail} className={classes.homePic} />
                                        </Grid>
                                        <Grid item container sm={12} md={4}
                                            direction="column"
                                            style={{ paddingLeft: "20px" }}
                                        >
                                            <Grid item>
                                                <Typography gutterBottom variant="h4" style={{ textAlign: "left" }}>
                                                    {category.title}
                                                </Typography>
                                                <Typography variant="subtitle1" style={{ textAlign: "left" }}>
                                                    {category.short_description}
                                                </Typography>
                                            </Grid>
                                            <Grid item container direction="row"
                                                justify="center"
                                                alignItems="center"
                                                style={{ padding: '35px 0' }}
                                            >
                                                <Grid item sm={6}>
                                                    <Typography variant="h5">
                                                        {product.price}円
                                                    </Typography>
                                                </Grid>
                                                <Grid item sm={6}>
                                                    <Link to={"/"} style={{ textDecoration: 'none' }}>
                                                        <Button
                                                            className={classes.button}
                                                            variant="contained"
                                                        >
                                                            購入
                                                        </Button>
                                                    </Link>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </div>
                                <Grid container direction="column" style={{ marginBottom: "40px" }}>
                                    <Grid item>
                                        <Typography gutterBottom variant="h5" style={{ textAlign: "left" }}>
                                            コース紹介
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="subtitle1" style={{textAlign: "left"}}>
                                            {/* {lines} */}
                                            {category.description}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <InstructorIntro
                                    instructor={instructor}
                                />
                                <CategoriesList
                                    product_id = {product_id}
                                    isLoading  = {isLoading}
                                    categories = {categories}
                                    switchBookmark ={(cidx, vidx, bookmarked, categoryVideo_id) => { this.switchBookmark(cidx, vidx, bookmarked, categoryVideo_id)}}
                                />
                            </div>
                            <Footer />
                        </div>
                }
            </div>
        );
    }
}

export default withRouter(connect(mapStateToProps)(withStyles(styles)(Product)))

/*
style     ={{backgroundColor: '#414141', padding: '35px 0'}}
style={{backgroundColor: '#707070'}}
*/