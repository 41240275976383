import React, { Component } from 'react';

import { withStyles } from "@material-ui/core/styles";
import { withRouter } from 'react-router';
import { connect } from 'react-redux'
import axios from 'axios';

import ConfirmationDialog from './ConfirmationDialog'

import Typography from '@material-ui/core/Typography';
import Button from "@material-ui/core/Button";
import InputBase from '@material-ui/core/InputBase';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Select from '@material-ui/core/Select';
import NativeSelect from '@material-ui/core/NativeSelect';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';

import './AdminStyle.css';

import { PUT_CATEGORY } from "../../config/apiUrl";


const styles = theme => ({
    section: {
        marginBottom: '30px'
    },
    formControl: {
        display: 'flex',
        paddingTop: '5px',
        paddingBottom: '5px',
        alignItems: 'center',
    },
    input: {
        width: '100%',
        borderRadius: 4,
        border: '1px solid #ced4da',
        backgroundColor: '#FDFDFD',
        padding: '3px 10px',
    },
    inputMulti: {
        width: '100%',
        borderRadius: 4,
        border: '1px solid #ced4da',
        backgroundColor: '#FDFDFD',
        padding: '10px',
    },
    form: {
        padding: 30,
    },
    button: {
        color: '#FDFDFD',
        backgroundColor: '#8BAFED',
        borderRadius: 4,
    },
    table: {
        tableLayout: 'auto'
    },
    err: {
        color: 'red'
    },
    header: {
        paddingLeft: '20px',
        paddingBottom: '5px',
        paddingTop: '5px',
        marginBottom: '10px',
        textAlign: 'left',
        width: '100%',
        backgroundColor: '#707070',
        borderRadius: 4,
        color: '#F8F8F8'
    },
});

class CategoryInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            error: [],
            openConfirmationModal: false,
            isDoing: false,
            category : this.props.category,
            product : this.props.product,
        }
    }
    changeCategoryParam(param, value) {
        const t = this.props.category
        t[param] = value;
        this.setState({ category: t })
    }

    changeProductParam(param, value) {
        const t = this.props.product
        t[param] = value;
        this.setState({ product: t })
    }

    handleClose = () => {
        this.setState({ 
            openConfirmationModal: false
        });
        };

    onCommit(){
        const category = this.state.category
        const product = this.state.product
        const data = {category, product}
        const pid = this.props.match.params.category_id
        this.setState({
            error:[],
            openConfirmationModal: true,
            isDoing: true
        })
        axios.put(PUT_CATEGORY(pid),{data})
        .then(res => {
            this.setState({ isDoing: false })
            this.props.history.push("/admin/categories/" + pid)
        })
        .catch(error => {
            this.setState({ loading: false, error: ["更新に失敗しました"] })
        })

    }

    render() {
        const { classes } = this.props;
        const category = this.state.category;
        const product = this.state.product;
        console.log(product)
        return (
            <div>
                {
                    this.state.isLoading ?
                        <img src="https://s3-ap-northeast-1.amazonaws.com/togo.static/img/loading.gif" /> :
                        <form className={classes.form}>
                            <div className={classes.section}>
                                <Typography variant="h6" gutterBottom className={classes.header}>
                                    基本情報
                                </Typography>
                                <div className={classes.formControl}>
                                    <Typography variant='h6' style={{ width: '30%', textAlign: 'left' }}>
                                        タイトル
                                    </Typography>
                                    <FormControl required fullWidth>
                                        <InputBase
                                            className={classes.input}
                                            variant='outlined'
                                            value={category.title}
                                            onChange={(e) => { this.changeCategoryParam("title", e.currentTarget.value) }}
                                        />
                                    </FormControl>
                                </div>
                                {
                                    product.ptype ?
                                        <div>
                                            <div className={classes.formControl}>
                                                <Typography variant='h6' style={{ width: '30%', textAlign: 'left' }}>
                                                    課金モデル
                                                </Typography>
                                                <FormControl required fullWidth>
                                                    <NativeSelect
                                                        native
                                                        // defaultValue={product.ptype}
                                                        defaultValue={30}
                                                        value={product.ptype}
                                                        onChange={(e) => { this.changeProductParam("ptype", e.currentTarget.value) }}
                                                        input={<OutlinedInput/>}
                                                    >
                                                        <option value={"サブスクリプション"}>サブスクリプション</option>
                                                        <option value={"買い切り"}>買い切り</option>
                                                    </NativeSelect>
                                                </FormControl>
                                            </div>
                                            <div className={classes.formControl}>
                                                <Typography variant='h6' style={{ width: '30%', textAlign: 'left' }}>
                                                    料金
                                                </Typography>
                                                <FormControl required fullWidth>
                                                    <InputBase
                                                        className={classes.input}
                                                        variant='outlined'
                                                        value={product.price}
                                                        onChange={(e) => { this.changeProductParam("price", e.currentTarget.value) }} />
                                                </FormControl>
                                            </div>
                                        </div> : null
                                }
                                {/* <div className={classes.formControl}>
                                    <Typography variant='h6' style={{ width: '30%', textAlign: 'left' }}>
                                        カテゴリータイプ
                                    </Typography>
                                    <FormControl required fullWidth>
                                        <Select
                                            native
                                            value={product.ctype}
                                            onChange={(e) => { this.changeProductParam("ctype", e.currentTarget.value) }}
                                            input={<OutlinedInput/>}
                                        >
                                            <option value={"プロダクト"}>プロダクト</option>
                                            <option value={"カテゴリー"}>カテゴリー</option>
                                        </Select>
                                    </FormControl>
                                </div> */}
                                <div className={classes.formControl}>
                                    <Typography variant='h6' style={{ width: '30%', textAlign: 'left' }}>
                                        概要
                                </Typography>
                                    <FormControl required fullWidth>
                                        <InputBase
                                            className={classes.inputMulti}
                                            variant='outlined'
                                            multiline
                                            rows='5'
                                            value={category.short_description}
                                            onChange={(e) => { this.changeCategoryParam("short_description", e.currentTarget.value) }} />
                                    </FormControl>
                                </div>
                                <div className={classes.formControl}>
                                    <Typography variant='h6' style={{ width: '30%', textAlign: 'left' }}>
                                        説明
                                    </Typography>
                                    <FormControl required fullWidth>
                                        <InputBase
                                            className={classes.inputMulti}
                                            variant='outlined'
                                            multiline
                                            rows='10'
                                            value={category.description}
                                            onChange={(e) => { this.changeCategoryParam("description", e.currentTarget.value) }} />
                                    </FormControl>
                                </div>
                                <div className={classes.formControl}>
                                    <Typography variant='h6' style={{ width: '30%', textAlign: 'left' }}>
                                        Thumbnail
                                    </Typography>
                                    <FormControl required fullWidth>
                                        <InputBase
                                            className={classes.input}
                                            variant='outlined'
                                            placeholder='https://xxx.com/thumbnail.jpg'
                                            value={category.thumbnail}
                                            onChange={(e) => { this.changeCategoryParam("thumbnail", e.currentTarget.value) }} />
                                    </FormControl>
                                </div>
                                <div style={{textAlign: "right"}} >
                                    <img src={category.thumbnail} style={{width: "50%"}}/>
                                </div>
                                {
                                    product.ptype ?
                                        <div className={classes.formControl}>
                                            <Typography variant='h6' style={{ width: '30%', textAlign: 'left' }}>
                                                状況
                                            </Typography>
                                            <FormControl required fullWidth>
                                                <div>
                                                    <FormControlLabel 
                                                        checked ={product.avail_flg===1} 
                                                        control ={<Radio color="default" />} 
                                                        label   ="配信中" 
                                                        onChange={(e) => { this.changeProductParam("avail_flg", 1) }}/>
                                                    <FormControlLabel 
                                                        checked ={product.avail_flg===0} 
                                                        control ={<Radio color="default" />} 
                                                        label   ="停止中" 
                                                        onChange={(e) => { this.changeProductParam("avail_flg", 0) }}/>
                                                </div>
                                            </FormControl>
                                        </div>
                                        : null
                                }
                            <Button
                                className={classes.button}
                                style={{float: 'right', width: '40%'}}
                                onClick={e => this.onCommit()}>
                                    更新
                            </Button>
                            <ConfirmationDialog
                                isDoing={this.state.isDoing}
                                openConfirmationModal={this.state.openConfirmationModal}
                                isDoingComment={"更新しています"}
                                isDoneComment={"更新が完了しました"}
                                handleClose={() => { this.handleClose() }}
                            />
                            </div>
                        </form>
                }
            </div>
        );
    }
}

export default withRouter(connect()(withStyles(styles)(CategoryInfo)))