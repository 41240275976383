import auth from './reducers/auth';
import { combineReducers } from 'redux';
import common from './reducers/common';
import home from './reducers/home';
import { routerReducer } from 'react-router-redux';
import videoData from './reducers/videoData'
import coursesData from './reducers/coursesData'
import bookmarkList from './reducers/bookmarks'
import product_data from './reducers/products'

export default combineReducers({
    auth,
    common,
    home,
    videoData,
    router: routerReducer,
    coursesData,
    bookmarkList,
    product_data,
});
