export const APP_LOAD = 'APP_LOAD';
export const HOME_PAGE_LOADED = 'HOME_PAGE_LOADED';
export const HOME_PAGE_UNLOADED = 'HOME_PAGE_UNLOADED';
export const ARTICLE_PAGE_LOADED = 'ARTICLE_PAGE_LOADED';
export const ADD_COMMENT = 'ADD_COMMENT';
export const DELETE_COMMENT = 'DELETE_COMMENT';
export const ARTICLE_FAVORITED = 'ARTICLE_FAVORITED';
export const ARTICLE_UNFAVORITED = 'ARTICLE_UNFAVORITED';
export const SET_PAGE = 'SET_PAGE';
export const APPLY_TAG_FILTER = 'APPLY_TAG_FILTER';
export const CHANGE_TAB = 'CHANGE_TAB';
export const PROFILE_PAGE_LOADED = 'PROFILE_PAGE_LOADED';
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const REGISTER = 'REGISTER';
export const LOGIN_PAGE_UNLOADED = 'LOGIN_PAGE_UNLOADED';
export const REGISTER_PAGE_UNLOADED = 'REGISTER_PAGE_UNLOADED';
export const ASYNC_START = 'ASYNC_START';
export const ASYNC_END = 'ASYNC_END';
export const EDITOR_PAGE_LOADED = 'EDITOR_PAGE_LOADED';
export const UPDATE_FIELD_AUTH = 'UPDATE_FIELD_AUTH';
export const UPDATE_FIELD_EDITOR = 'UPDATE_FIELD_EDITOR';
export const PROFILE_FAVORITES_PAGE_LOADED = 'PROFILE_FAVORITES_PAGE_LOADED';
export const SET_FORMATING = 'SET_FORMATING'

export const SET_VIDEOS = 'SET_VIDEOS'
export const FETCH_VIDEOS = 'FETCH_VIDEOS'
export const FETCH_VIDEOS_DONE = 'FETCH_VIDEOS_DONE'
export const SET_LECTURE_TITLES = 'SET_LECTURE_TITLES'

export const FETCH_BOOKMARK_LIST = 'FETCH_BOOKMARK_LIST'
export const FETCH_BOOKMARK_LIST_DONE = 'FETCH_BOOKMARK_LIST_DONE'
export const GET_BOOKMARK_LIST = 'GET_BOOKMARK_LIST'
export const SWITCH_BOOKMARK = 'SWITCH_BOOKMARK'
export const SWITCH_BOOKMARK_ONLIST = 'SWITCH_BOOKMARK_ONLIST'
export const GET_ALL = 'GET_ALL'

export const SET_COURSES = 'SET_COURSES'
export const FETCH_COURSES = 'FETCH_COURSES'
export const SET_COURSE_DATA = 'SET_COURSE_DATA'

//PRODUCT
export const FETCH_PRODUCT = 'FETCH_PRODUCT'
export const FETCH_PRODUCT_DONE = 'FETCH_PRODUCT_DONE'
export const GET_PRODUCT_DATA = 'GET_PRODUCT_DATA'
export const UPDATE_PRODUCT_DATA = 'UPDATE_PRODUCT_DATA'